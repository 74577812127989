import React, { useContext } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import {
  formatTalentSource,
  prepareAvailability,
  prepareHourlyRate,
  prepareLocation,
} from "components/UserCardList/helpers/userCard";

import { ModalContext } from "context/providers";

import SvgButton from "components/base/SvgButton";
import SvgIcon from "components/base/SvgIcon";

const UserInfo = ({ hit }) => {
  const { showModal } = useContext(ModalContext);

  const showUserInfoModal = () => {
    if (hit.match?.applicationId) {
      showModal({ type: "userInfo", applicationId: hit.match.applicationId });

      return;
    }

    showModal({ type: "userInfo", userId: hit.id });
  };

  const renderLanguages = (languages) => {
    if (!languages || !languages?.length) {
      return "Not Specified";
    }

    return languages.map((lang, langIndex) => {
      return (
        <span key={lang.language}>
          {langIndex > 0 && ", "}
          {lang.language}
          {`(${lang.level[0]}${lang.level.slice(1).toLowerCase()})`}
        </span>
      );
    });
  };

  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="mb-3">
        <div className="flex">
          <p className="text-text-grey-900 text-2xl whitespace-nowrap font-bold">
            {`${hit.given_name} ${hit.family_name}`}
          </p>

          <SvgButton
            icon="info"
            className="ml-2"
            onClick={showUserInfoModal}
            // onClick={() => showModal({ type: "userInfo", userId: hit.id })}
          />
        </div>

        {hit.username && (
          <a
            className="cursor-pointer text-base text-text-grey-900 opacity-40 font-bold hover:opacity-60 transition-all"
            href={`${process.env.REACT_APP_DOMAIN}/#/profile/${hit.username}`}
            target="_blank"
            rel="noreferrer"
          >
            @{hit.username || hit.identity_username}
          </a>
        )}
      </div>

      <p className="text-base text-grey-800">
        {prepareLocation(hit.location) || hit.locale}
      </p>

      <p className="text-xs font-bold">
        Hourly Rate:{" "}
        <span className="text-gray-500 font-normal text-grey-800">
          {prepareHourlyRate(hit.ratePerHour)}
        </span>
      </p>

      <p className="text-xs font-bold">
        Availability:{" "}
        <span className="text-gray-500 font-normal text-grey-800">
          {prepareAvailability(hit.availability)}
        </span>
      </p>

      <p className="text-xs font-bold">
        Known Languages:{" "}
        <span className="text-gray-500 font-normal text-grey-800">
          {renderLanguages(hit.knownLanguages)}
        </span>
      </p>

      {hit?.talentSource && (
        <p className="text-xs font-bold">
          Talent Source & Markup:{"  "}
          <span
            className="text-gray-500 font-normal text-grey-800"
            title="Talent markup rounded to 2 decimal places"
          >
            {formatTalentSource(hit)}
          </span>
        </p>
      )}

      <p className="text-xs font-bold">
        Member since:{" "}
        <span className="text-gray-500 font-normal text-grey-800">
          {`${dayjs(hit.createdAt).format("MM/DD/YYYY")}`}
        </span>
      </p>

      {hit?.resumeLocation && (
        <div>
          <a
            href={`https://res.cloudinary.com/${
              process.env.REACT_APP_CLOUDINARY_CLOUDNAME
            }/image/upload/v${Date.now()}/${hit.resumeLocation}.pdf`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <SvgIcon
              type="downloadFile"
              className="w-9 h-12 transform transition duration-500 hover:scale-110"
            />
          </a>
        </div>
      )}
    </div>
  );
};

UserInfo.propTypes = {
  hit: PropTypes.object,
};

UserInfo.defaultProps = {
  hit: {},
};

export default UserInfo;
