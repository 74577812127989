import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

import {
  SearchBox,
  HitsPerPage,
  Stats,
  Pagination,
} from "react-instantsearch-dom";

import { MatchContext, SearchContext } from "context/providers";
import { mapHits } from "utils/helpers/search";
import { mapMatchesToHit } from "utils/helpers/match";
import { createFilter } from "./helpers/userCard";

import UserCardResult from "./components/UserCardResult";
import DownloadResults from "components/DownloadResults";
import { Filters } from "./components";
import Checkbox from "components/base/Checkbox";

const DummyHits = ({ message, renderMessage }) => {
  if (!!renderMessage) {
    return (
      <div className="text-center text-red-400 text-lg mb-3">
        {renderMessage()}
      </div>
    );
  }

  return <div className="text-center text-red-400 text-lg mb-3">{message}</div>;
};

const UserCardList = ({
  showStatusColor,
  stats,
  search,
  pagination,
  downloadResults,
  collectionKey,
  noResultsMessage,
  renderCustomNoResults,
  showActions,
  excludedMatchStatuses,
  matchStatus,
  filter,
  customFilter,
}) => {
  const { matches, bestFit } = useContext(MatchContext);
  const { hits, hideSkipped, setHideSkipped } = useContext(SearchContext);

  const [filters, setFilters] = useState({});

  const { finalItems, sourceItems } = useMemo(() => {
    let finalItems = [];
    let sourceItems;

    switch (collectionKey) {
      case "hits": {
        finalItems = mapHits(hits, matches);
        sourceItems = mapHits(hits, matches);
        break;
      }

      case "bestFit": {
        finalItems = mapHits(bestFit, matches);
        sourceItems = mapHits(bestFit, matches);
        break;
      }

      case "match": {
        finalItems = mapMatchesToHit(
          matches,
          matchStatus,
          excludedMatchStatuses
        );
        sourceItems = mapMatchesToHit(
          matches,
          matchStatus,
          excludedMatchStatuses
        );
        break;
      }

      default: {
        break;
      }
    }

    if (!!filter.length) {
      const finalItemsCpy = finalItems.filter((hit) => {
        let conditionValue;

        Object.keys(filters).forEach((filtersKey) => {
          const filtersByKey = filters[filtersKey].keys || {};
          Object.keys(filtersByKey).forEach((filterKey) => {
            if (filtersByKey[filterKey].value) {
              if (filters[filtersKey].filterCondition) {
                conditionValue =
                  conditionValue && hit.match?.[filtersKey] === filterKey;
              } else {
                conditionValue =
                  conditionValue || hit.match?.[filtersKey] === filterKey;
              }
            }
          });
        });

        return conditionValue;
      });

      return { finalItems: finalItemsCpy, sourceItems };
    }

    return { finalItems, sourceItems };
  }, [
    collectionKey,
    hits,
    bestFit,
    matches,
    matchStatus,
    excludedMatchStatuses,
    filter,
    filters,
  ]);

  const clearFilters = () => {
    setFilters((prev) => {
      const cleared = {};

      Object.keys(prev).forEach((filterKey) => (cleared[filterKey] = true));

      return cleared;
    });
  };

  const renderCustomFilter = () => {
    switch (customFilter) {
      case "hideSkipped": {
        return (
          <Checkbox
            label="Hide Skipped Users"
            className="mt-0"
            checked={hideSkipped}
            onChange={setHideSkipped}
          />
        );
      }

      default:
        break;
    }
  };

  useEffect(() => {
    if (!!filter.length) {
      const createdFilters = {};
      filter.forEach((f) => {
        createdFilters[f] = { keys: createFilter(sourceItems, f) };

        if (f === "subStatus") {
          createdFilters[f].filterCondition = true;
        } else {
          createdFilters[f].filterCondition = false;
        }
      });
      setFilters(createdFilters);
    }

    return () => {
      if (!!filter.length) {
        clearFilters();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex justify-between items-center">
        {customFilter && renderCustomFilter()}

        {stats && <Stats />}
      </div>

      {(stats || customFilter) && (
        <hr className="w-full border-t border-gray-300" />
      )}

      {search && (
        <SearchBox
          translations={{
            placeholder: "Search for users",
          }}
          autoFocus
        />
      )}

      {!!filter.length && <Filters filters={filters} onChange={setFilters} />}

      {!!finalItems.length ? (
        finalItems.map((hit) => {
          const clonedHit = cloneDeep(hit);

          return (
            <UserCardResult
              key={clonedHit.id}
              hit={clonedHit}
              showStatusColor={showStatusColor}
              showActions={showActions}
              collectionKey={collectionKey}
            />
          );
        })
      ) : (
        <DummyHits
          message={noResultsMessage}
          renderMessage={renderCustomNoResults}
        />
      )}

      {pagination && (
        <div className="my-8 flex justify-between">
          <Pagination />

          <HitsPerPage
            items={[
              { value: 10, label: "Show 10 hits" },
              { value: 25, label: "Show 25 hits" },
            ]}
            defaultRefinement={10}
          />
        </div>
      )}

      {downloadResults && <DownloadResults />}
    </div>
  );
};

UserCardList.propTypes = {
  showStatusColor: PropTypes.bool,
  stats: PropTypes.bool,
  search: PropTypes.bool,
  pagination: PropTypes.bool,
  downloadResults: PropTypes.bool,
  collectionKey: PropTypes.string,
  noResultsMessage: PropTypes.string,
  renderCustomNoResults: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([undefined]),
  ]),
  showActions: PropTypes.bool,
  filter: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([""])]),
  customFilter: PropTypes.string,
  excludedMatchStatuses: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.oneOf([undefined]),
  ]),
  matchStatus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([undefined]),
  ]),
};

UserCardList.defaultProps = {
  showStatusColor: false,
  stats: false,
  search: false,
  pagination: false,
  downloadResults: false,
  collectionKey: "hits",
  noResultsMessage: "No results to display",
  renderCustomNoResults: undefined,
  showActions: false,
  filter: [],
  customFilter: "",
  excludedMatchStatuses: undefined,
  matchStatus: undefined,
};

export default UserCardList;
