import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./index.module.css";

import {
  getButtonClassName,
  getButtonText,
} from "components/UserCardList/helpers/userCard";

import { JOB_APPLICATION_MATCH_STATUS } from "lookup";
import { ModalContext } from "context/providers";

import SvgIcon from "components/base/SvgIcon";

const DEFAULT_BUTTON_CLASS_NAME =
  "transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex items-center";

const HitActionButtons = ({
  hit,
  isLoading,
  showActions,
  matchAction,
  jobType,
}) => {
  const { showModal } = useContext(ModalContext);

  const oneOfButtonsLoading = useMemo(() => {
    return !!Object.values(isLoading).filter((el) => !!el).length;
  }, [isLoading]);

  const hasJobTypeApplication = hit.applications.some(
    (app) => app.jobTypeId === jobType?.id && !app.isNotActive
  );

  return (
    <div>
      <div className="flex items-center gap-4">
        {!hit.associatedMatch && showActions && hasJobTypeApplication && (
          <>
            <button
              className={classNames(
                DEFAULT_BUTTON_CLASS_NAME,
                "bg-green-700 text-green-800",
                getButtonClassName(
                  "match",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.MATCHED]
                )
              )}
              disabled={oneOfButtonsLoading}
              onClick={() => matchAction(JOB_APPLICATION_MATCH_STATUS.MATCHED)}
            >
              <SvgIcon
                type="heart"
                fill="#166534"
                className="w-[20px] h-[20px]"
              />

              <span className="ml-2">
                {getButtonText(
                  "match",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.MATCHED]
                )}
              </span>
            </button>

            <button
              className={classNames(
                DEFAULT_BUTTON_CLASS_NAME,
                "bg-blue-600 text-blue-700",
                getButtonClassName(
                  "shortlist",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]
                )
              )}
              disabled={oneOfButtonsLoading}
              onClick={() =>
                matchAction(JOB_APPLICATION_MATCH_STATUS.SHORTLISTED)
              }
            >
              <SvgIcon
                type="focus"
                fill="#075985"
                className="w-[20px] h-[20px]"
              />

              <span className="ml-2">
                {getButtonText(
                  "shortlist",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]
                )}
              </span>
            </button>

            <button
              className={classNames(
                DEFAULT_BUTTON_CLASS_NAME,
                "bg-orange-100 text-orange-200",
                getButtonClassName(
                  "skip",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SKIPPED]
                )
              )}
              disabled={oneOfButtonsLoading}
              onClick={() => matchAction(JOB_APPLICATION_MATCH_STATUS.SKIPPED)}
            >
              <SvgIcon
                type="skip"
                fill="#EE923E"
                className="w-[20px] h-[20px]"
              />

              <span className="ml-2">
                {getButtonText(
                  "skip",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SKIPPED]
                )}
              </span>
            </button>
          </>
        )}

        {!hasJobTypeApplication && jobType && (
          <div className={styles.tooltip}>
            <span className={styles["tooltip-preview"]}>
              {getButtonText("noActionJobTypeApp").slice(0, 46)}...
            </span>
            <span className={styles["tooltip-text"]}>
              {getButtonText("noActionJobTypeApp")}
            </span>
          </div>
        )}

        <button
          className={classNames(
            DEFAULT_BUTTON_CLASS_NAME,
            "bg-yellow-200 text-yellow-400"
          )}
          onClick={() =>
            showModal({
              type: "note",
              title: `User notes: ${hit.username}`,
              userId: hit.id,
            })
          }
        >
          <SvgIcon type="note" className="w-[15px] h-[15px]" fill="#857807" />

          <span className="ml-2">{`Notes${
            !!hit.notesCount ? ` (${hit.notesCount})` : ""
          }`}</span>
        </button>
      </div>
    </div>
  );
};

HitActionButtons.propTypes = {
  hit: PropTypes.object,
  isLoading: PropTypes.object,
  showActions: PropTypes.bool,
  matchAction: PropTypes.func,
};

HitActionButtons.defaultProps = {
  hit: {},
  showActions: false,
  isLoading: {
    [JOB_APPLICATION_MATCH_STATUS.MATCHED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SKIPPED]: false,
  },
  matchAction: () => {},
};

export default HitActionButtons;
