import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Highlight, connectRefinementList } from "react-instantsearch-dom";
import classNames from "classnames";

import Chip from "./Chip";

const RefinementList = ({
  items,
  refine,
  isFromSearch,
  searchForItems,
  predefinedValue,
  currentRefinement,
  placeholder,
  limit,
  showChip,
  showCount,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [chips, setChips] = useState(currentRefinement);

  useEffect(() => {
    setChips(currentRefinement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRefinement.length]);

  useEffect(() => {
    if (predefinedValue.length) {
      refine([...predefinedValue]);
    }

    if (!predefinedValue.length) {
      refine([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predefinedValue.length]);

  const finalItems = useMemo(() => {
    if (expanded && items.length > limit) {
      return items;
    }

    return items.slice(0, limit + 1);
  }, [expanded, items, limit]);

  const updateExpanded = () => setExpanded((prev) => !prev);

  const handleChange = (newItem, checked) => {
    let newRefinement;

    if (checked) {
      newRefinement = [...currentRefinement, newItem];
    } else {
      newRefinement = currentRefinement.filter((item) => item !== newItem);
    }

    refine(newRefinement);
  };

  const handleDeleteAll = () => {
    refine([]);
  };

  return (
    <>
      <input
        className="border block w-full px-2 py-1 text-sm"
        type="search"
        placeholder={placeholder}
        onChange={(event) => searchForItems(event.currentTarget.value)}
      />
      <div className="flex flex-wrap gap-1 my-3">
        {showChip &&
          chips.map((el) => (
            <Chip
              key={el}
              value={el}
              onClose={() => handleChange(el, false)}
              isActive
            />
          ))}

        {showChip && chips.length > 2 && (
          <Chip value="Clear all" onClick={handleDeleteAll} isActive />
        )}
      </div>
      <ul className="ais-RefinementList-list">
        {finalItems.map((item) => (
          <li
            key={item.label}
            className={classNames("mt-1 ais-RefinementList-item", {
              "ais-RefinementList-item--selected": item.isRefined,
            })}
          >
            <label className="flex items-center text-sm whitespace-nowrap ais-RefinementList-label">
              <input
                type="checkbox"
                className="ais-RefinementList-checkbox"
                checked={item.isRefined}
                onChange={(event) =>
                  handleChange(item.label, event.target.checked)
                }
              />
              <span
                className={classNames("mx-2 overflow-hidden text-ellipsis", {
                  "font-bold": item.isRefined,
                })}
              >
                {!isFromSearch ? (
                  item.label
                ) : (
                  <Highlight attribute="label" hit={item} />
                )}
              </span>
              {showCount && (
                <span className="px-1 border rounded-full bg-gray-100">
                  {item.count}
                </span>
              )}
            </label>
          </li>
        ))}
      </ul>

      {items.length > limit && (
        <button
          onClick={updateExpanded}
          className="ais-RefinementList-showMore"
        >
          {expanded ? "Show less" : "Show more"}
        </button>
      )}
    </>
  );
};

RefinementList.propTypes = {
  items: PropTypes.array,
  refine: PropTypes.func,
  isFromSearch: PropTypes.bool,
  searchForItems: PropTypes.func,
  predefinedValue: PropTypes.array,
  currentRefinement: PropTypes.array,
  placeholder: PropTypes.string,
  limit: PropTypes.number,
  showChip: PropTypes.bool,
  showCount: PropTypes.bool,
};

RefinementList.defaultProps = {
  items: [],
  refine: () => {},
  isFromSearch: false,
  searchForItems: () => {},
  predefinedValue: [],
  currentRefinement: [],
  placeholder: "",
  limit: 5,
  showChip: false,
  showCount: true,
};

export default connectRefinementList(RefinementList);
