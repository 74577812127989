import React from "react";
import PropTypes from "prop-types";

import { USER_TYPES } from "lookup";

import ImageViewer from "components/ImageViewer";
import SvgIcon from "components/base/SvgIcon";

const DummyPhoto = () => <div className="w-[100px] h-[100px] bg-[#D9D9D9]" />;

const UserContacts = ({ hit }) => {
  const renderCustomerTag = () => {
    if (hit.userType !== USER_TYPES.CUSTOMER) {
      return null;
    }
    return (
      <div className="w-[100%] mt-4 flex flex-col gap-y-1">
        <p className="text-center bg-[#0495b7] text-white rounded">Customer</p>
        <p className="text-center">{hit.company}</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col flex-none w-full">
      {hit.headshotKey ? (
        <div className="w-[100px] h-[100px] overflow-hidden">
          <ImageViewer
            objectKey={hit.headshotKey}
            width={100}
            responsive={false}
          />
        </div>
      ) : (
        <DummyPhoto />
      )}

      <hr className="w-full border-t border-[#D9D9D9] my-4" />

      <div className="grid grid-cols-2 gap-x-2 gap-y-4">
        <a
          className="flex justify-center text-sm transform transition duration-500 hover:scale-110"
          href={`mailto:${hit.email}`}
          target="_blank"
          rel="noreferrer"
        >
          <SvgIcon type="email" />
        </a>

        {hit?.phone?.number ? (
          <a
            className="flex justify-center text-sm transform transition duration-500 hover:scale-110"
            href={`tel:${hit.phone.number}`}
            target="_blank"
            rel="noreferrer"
          >
            <SvgIcon type="phone" className="h-5 w-5" />
          </a>
        ) : null}

        {hit?.phone?.whatsAppAllowed ? (
          <a
            className="flex justify-center text-sm transform transition duration-500 hover:scale-110"
            href={`whatsapp://send?phone=${hit.phone.number}`}
            target="_blank"
            rel="noreferrer"
          >
            <SvgIcon type="whatsapp" className="h-5 w-5" />
          </a>
        ) : null}
      </div>

      {renderCustomerTag()}
    </div>
  );
};

UserContacts.propTypes = {
  hit: PropTypes.object,
};

UserContacts.defaultProps = {
  hit: {},
};

export default UserContacts;
