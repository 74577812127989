import React, { useContext, useMemo } from "react";

import { MatchContext, ModalContext, SearchContext } from "context/providers";

import { CustomLinkModal, NoteModal, UserInfoModal } from "./modals";

const MODALS_BY_TYPE = {
  userInfo: UserInfoModal,
  link: CustomLinkModal,
  note: NoteModal,
};

const TEXT_CONTENT_LINKING = {
  user: {
    editorLabel: "Create your user note",
    editorSubLabel:
      "Remember: These notes are associated with the user profile",
  },
  match: {
    editorLabel: "Create your match note",
    editorSubLabel:
      "Remember: These notes are associated with the match record",
  },
};

const DummyModal = () => <></>;

const ModalContainer = () => {
  const { activeModal, hideModal } = useContext(ModalContext);
  const { matches, bestFit } = useContext(MatchContext);
  const { hits } = useContext(SearchContext);

  const { user, ...rest } = useMemo(() => {
    if (activeModal?.userId) {
      const user = [
        ...hits,
        ...bestFit,
        ...matches.map((m) => m.application?.user),
      ].find((hit) => hit?.id === activeModal.userId);

      return {
        user,
        ...TEXT_CONTENT_LINKING.user,
        ...activeModal,
      };
    }

    if (activeModal?.applicationId) {
      const user = matches.find(
        (hit) => hit.applicationId === activeModal.applicationId
      );

      return { user, ...TEXT_CONTENT_LINKING.match, ...activeModal };
    }

    return { user: null };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModal]);

  const modalTarget = useMemo(() => {
    if (activeModal?.applicationId) {
      return "Match";
    }

    if (activeModal?.userId) {
      return "User";
    }
  }, [activeModal]);

  const ModalComponent = useMemo(() => {
    if (activeModal) {
      return MODALS_BY_TYPE[activeModal.type] || DummyModal;
    }

    return DummyModal;
  }, [activeModal]);

  return (
    <ModalComponent
      user={user}
      modalTarget={modalTarget}
      onClose={hideModal}
      {...rest}
    />
  );
};

export default ModalContainer;
