import Base from "./Base.js";

import { updateUser } from "api/graphql/mutations.js";

import {
  getUserActivityHistory,
  getUserByEmail,
  getUserByUsername,
} from "./graphql/queries.js";

export default class UserAPI extends Base {
  update(payload) {
    return this.apiClient.request({ query: updateUser, payload });
  }

  getActivityHistory(payload) {
    return this.apiClient.request({ query: getUserActivityHistory, payload });
  }

  getByUsername(payload) {
    return this.apiClient.request({ query: getUserByUsername, payload });
  }

  getByEmail(payload) {
    return this.apiClient.request({ query: getUserByEmail, payload });
  }
}
