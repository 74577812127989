import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TableWithPagination from "../TableWithPagination";
import SvgIcon from "../SvgIcon";

const Tabs = ({ items, className }) => {
  const [activeTab, setActiveTab] = useState(items[0]);

  const renderTabs = () => {
    return (
      <div className="flex w-full">
        {items.map((item) => {
          const isTabActive = activeTab.title === item.title;

          return (
            <div
              key={item.title}
              onClick={() => setActiveTab(item)}
              className={classNames(
                "hover:border-b-2 hover:!border-[#0495B7] whitespace-nowrap hover:fill-[#0495B7] flex items-center text-text-grey-900 font-medium text-lg mx-6 pb-2 hover:text-[#0495B7] transition-all cursor-pointer relative -bottom-[2px] border-b-2 border-[transparent]",
                {
                  "text-[#0495B7] border-b-2 !border-[#0495B7]": isTabActive,
                }
              )}
            >
              {item.icon && (
                <SvgIcon
                  type={item.icon}
                  className={classNames("mr-2", {
                    "fill-[#0495B7]": isTabActive,
                  })}
                />
              )}

              <p>{item.title}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const renderContent = useCallback(() => {
    switch (activeTab?.type) {
      case "table": {
        return (
          <div className="overflow-scroll">
            <TableWithPagination {...activeTab} />
          </div>
        );
      }

      case "text": {
        return <p>{activeTab.content}</p>;
      }

      case "custom": {
        return activeTab.renderCustomContent();
      }

      default: {
        return <></>;
      }
    }
  }, [activeTab]);

  if (!items.length) {
    return null;
  }

  return (
    <div className={classNames("flex flex-col", className)}>
      <div className="mb-4 border-b-2 border-text-grey-900">{renderTabs()}</div>
      <div>{renderContent()}</div>
    </div>
  );
};

Tabs.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
};

Tabs.defaultProps = {
  className: "",
};

export default Tabs;
