import produce from "immer";

import { USER_TYPES } from "lookup";
import { contextConstants } from "context/constants";

export const initialState = {
  searchState: {
    refinementList: {
      userType: [USER_TYPES.FREELANCER],
      "skills.name": [],
    },
  },
  hits: [],
  hideSkipped: true,
  operators: {
    "skills.name": "and",
    "applications.jobTypeTitle": "and",
    "knownLanguages.language": "and",
    "assessments.testName": "and",
  },
};

export const reducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case contextConstants.search.SEARCH_STATE_CHANGED: {
      draft.searchState = payload.searchState;
      break;
    }

    case contextConstants.search.HITS_LOADED: {
      draft.hits = payload.hits;
      break;
    }

    case contextConstants.search.OPERATOR_UPDATED: {
      draft.operators = payload.operators;
      break;
    }

    case contextConstants.search.HIDE_SKIPPED_CHANGED: {
      draft.hideSkipped = payload.hideSkipped;
      break;
    }

    default:
      break;
  }
});
