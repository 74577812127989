import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import HelpText from "components/HelpText";

const Checkbox = ({ label, checked, helpText, onChange, className }) => {
  const id = useMemo(() => Math.random() / Math.random(), []);

  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <div className={classNames("mt-2 flex items-center", className)}>
      <input
        id={id}
        className="mr-2"
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />

      <label className="select-none font-bold" htmlFor={id}>
        {label}
      </label>

      {helpText && <HelpText text={helpText} className="ml-2" />}
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  helpText: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  label: "",
  checked: false,
  helpText: "",
  onChange: () => {},
  className: "",
};

export default Checkbox;
