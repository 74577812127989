import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom-v5-compat";
import MarkdownIt from "markdown-it";
import underline from "markdown-it-plugin-underline";
import { cloneDeep, set } from "lodash";

import api from "apiSingleton";
import { getCustomerName } from "utils/helpers/jobOppDetails";
import { JOB_OPPORTUNITY_TIME_COMMITMENT } from "lookup";

import { JobsContext, NotesContext } from "context/providers";

import Applications from "components/Applications/index";
import Input from "components/base/Input";
import HelpText from "components/HelpText";
import Modal from "components/Modal";
import Notes from "components/Notes";

const mdParser = new MarkdownIt().use(underline);
// Open links in new tab
const defaultRender =
  mdParser.renderer.rules.link_open ||
  ((tokens, idx, options, env, self) => {
    return self.renderToken(tokens, idx, options);
  });

mdParser.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  // If you are sure other plugins can't add `target` - drop check below
  const aIndex = tokens[idx].attrIndex("target");

  if (aIndex < 0) {
    tokens[idx].attrPush(["target", "_blank"]); // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = "_blank"; // replace value of existing attr
  }

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

// Display Job Length, if Weeks is present, display weeks,
// else display months
const JobLength = ({ jobOpp }) => {
  if (jobOpp?.jobLengthInWeeks) {
    return (
      <span>
        {jobOpp.jobLengthInWeeks}{" "}
        {jobOpp.jobLengthInWeeks > 1 ? "weeks" : "week"}
      </span>
    );
  }
  return (
    <span>
      {jobOpp?.jobLength} {jobOpp?.jobLength > 1 ? "months" : "month"}
    </span>
  );
};

const JobOppDetails = ({ user, signOut }) => {
  const [isMounted, setIsMounted] = useState(false);
  const params = useParams();

  const { loadingJobOpp, jobOpp, initJob, updateJob, updateJobLocally } =
    useContext(JobsContext);

  const {
    jobOppNotes,
    job: initJobNotes,
    add: addNote,
    remove: removeNote,
  } = useContext(NotesContext);

  const [inputs, setInputs] = useState({
    torcOwner: {
      value: jobOpp.torcOwner?.username || "",
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      focused: false,
    },
  });

  const getTimeCommitmentLabel = () => {
    if (jobOpp.timeCommitment === JOB_OPPORTUNITY_TIME_COMMITMENT.FULLTIME) {
      return "Full-time";
    }

    return "Part-time";
  };

  const getStartDate = () => {
    const startDate = new Date(jobOpp.startDate);
    if (startDate <= new Date()) {
      return "Immediate";
    }

    const year = startDate.getFullYear();
    const month = (1 + startDate.getMonth()).toString().padStart(2, "0");
    const day = startDate.getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
  };

  const handleInputChange = ({ value, valueKey }) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: {
        ...prev[valueKey],
        errorMessage: "",
        value,
      },
    }));
  };

  const updateInputByPath = (path, value) => {
    setInputs((prev) => {
      const output = cloneDeep(prev);
      const updated = set(output, path, value);

      return updated;
    });
  };

  const addPrimaryMatcher = async () => {
    const shouldUpdate =
      !!inputs.torcOwner.value &&
      inputs.torcOwner.value !== jobOpp.torcOwner?.username;

    let userData;

    if (shouldUpdate) {
      const searchByEmail = inputs.torcOwner.value.includes("@");
      const payloadKey = searchByEmail ? "getUserByEmail" : "getUserByUsername";

      updateInputByPath("torcOwner.isLoading", true);

      try {
        let user;

        if (searchByEmail) {
          user = await api.user.getByEmail({
            email: inputs.torcOwner.value,
          });
        } else {
          user = await api.user.getByUsername({
            username: inputs.torcOwner.value,
          });
        }

        const id = user.data?.[payloadKey]?.items?.[0];

        if (id) {
          userData = user.data?.[payloadKey]?.items?.[0];
        } else {
          updateInputByPath("torcOwner.errorMessage", "User not found");
          updateInputByPath("torcOwner.isLoading", false);

          return;
        }
      } catch (error) {
        updateInputByPath("torcOwner.errorMessage", "User not found");
        updateInputByPath("torcOwner.isLoading", false);
      }

      try {
        const payload = { ...userData };

        await updateJob(jobOpp.id, { torcOwner: { ...payload } });

        updateInputByPath("torcOwner.focused", false);
        updateInputByPath(
          "torcOwner.successMessage",
          "Primary Matcher successfully updated"
        );

        updateJobLocally(payload, "torcOwner");

        setTimeout(() => {
          updateInputByPath("torcOwner.successMessage", "");
        }, 3000);
      } catch (error) {
        const errorMessage =
          error.errors?.[0]?.message || "Something went wrong";

        updateInputByPath("torcOwner.errorMessage", errorMessage);
      } finally {
        updateInputByPath("torcOwner.isLoading", false);
        updateInputByPath("torcOwner.value", userData.username);
      }
    } else {
      updateInputByPath("torcOwner.focused", false);
    }
  };

  const initNotes = async () => {
    try {
      await initJobNotes(jobOpp);
    } catch (error) {
      console.error("initJobNotes error: ", error);
    }
  };

  const createNote = async (note) => {
    try {
      const { content, isPublic } = note;
      const { id: jobOpportunityId } = jobOpp;

      await addNote({ content, isPublic, jobOpportunityId });
      await initNotes();

      return { isSuccess: true };
    } catch (error) {
      console.error("createNote error: ", error);

      return { isSuccess: false };
    }
  };

  const handleDeleteNote = async (id) => {
    await removeNote({ id });
    await initNotes();
  };

  useEffect(() => {
    (async () => {
      if (!isMounted) {
        await initJob(params.id);

        setIsMounted(true);
      }
    })();

    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (!loadingJobOpp && !jobOppNotes[jobOpp.id]) {
      initNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingJobOpp]);

  useEffect(() => {
    if (jobOpp?.torcOwner?.username) {
      updateInputByPath("torcOwner.value", jobOpp.torcOwner.username);
    }
  }, [jobOpp?.torcOwner?.username]);

  if (!isMounted || loadingJobOpp) {
    return (
      <div className="flex justify-center pt-8">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <div>
      <Applications signOut={signOut} />

      <Modal>
        <div className="flex flex-col md:flex-row">
          <div style={{ flex: `1.7 1 0px` }}>
            <div className="px-8 py-12 w-full">
              <div className="text-blue-400 font-nexa text-xl mb-4">
                Job Details
              </div>
              <div className="text-blue-400 font-rubik mb-4 pb-2 font-bold italic leading-snug">
                <p>
                  {getTimeCommitmentLabel()} | <JobLength jobOpp={jobOpp} />
                </p>

                <p>Customer Name: {getCustomerName(jobOpp)}</p>
                <p>Start Date: {getStartDate()}</p>
                <p>
                  Hourly Rate: ${jobOpp.minRate?.value}&ndash;$
                  {jobOpp.maxRate?.value}
                </p>
                <p>Timezone: {jobOpp.timezone?.label}</p>
                <p>
                  Timezone Overlap:{" "}
                  {jobOpp.timeOverlap > 1 && jobOpp.timeOverlap < 8
                    ? `${jobOpp.timeOverlap} hours`
                    : jobOpp.timeOverlap >= 8
                    ? "All hours"
                    : "No Restriction"}
                </p>
                <p>Job Role: {jobOpp.jobType.title}</p>

                {inputs.torcOwner.focused ? (
                  <Input
                    className="!flex-row items-center"
                    labelClassName="!my-0 mr-2"
                    inputClassName="border-dashed !p-1 not-italic focus:border-solid text-black font-normal"
                    label="Primary Matcher: "
                    placeholder="Rep username or email"
                    helpText="Internal User working on this job"
                    helpTextPlacement="left"
                    errorMessage={inputs.torcOwner.errorMessage}
                    isLoading={inputs.torcOwner.isLoading}
                    value={inputs.torcOwner.value}
                    valueKey="torcOwner"
                    onChange={handleInputChange}
                    onBlur={addPrimaryMatcher}
                    autoFocus
                  />
                ) : (
                  <div className="relative flex flex-col">
                    <div
                      className="select-none flex"
                      onDoubleClick={() =>
                        updateInputByPath("torcOwner.focused", true)
                      }
                    >
                      <span>Primary Matcher:</span>

                      <HelpText
                        text="Internal User working on this job"
                        className="mx-2"
                      />
                      {inputs.torcOwner.value || "Not specified"}
                    </div>

                    <p className="text-green-500 text-sm font-bold mt-1 transition-all">
                      {inputs.torcOwner.successMessage}
                    </p>
                  </div>
                )}
              </div>
              <Notes
                notes={jobOppNotes[jobOpp.id]}
                notesTarget="Job"
                loggedInUser={user}
                notesCount={jobOpp.notesCount}
                onSubmit={createNote}
                onDelete={handleDeleteNote}
                borderTop
              />
            </div>
          </div>
          <div
            className="bg-zestygreen"
            style={{ flexBasis: `2px`, alignSelf: `stretch` }}
          />
          <div className="p-12 flex flex-col gap-8" style={{ flex: `2 1 0px` }}>
            <div className="flex flex-col gap-4">
              <h1 className="text-3xl font-bold text-black tracking-wide font-nexa">
                {jobOpp.title}
              </h1>
              <h4 className="font-rubik text-base italic text-blue-400 font-light">
                {jobOpp.skills.map((s) => s.name).join(", ")}
              </h4>
            </div>
            <p
              className="prose"
              dangerouslySetInnerHTML={{
                __html: mdParser.render(jobOpp.overview),
              }}
            />
            <h3 className="-mb-6 text-lg">Responsibilities</h3>
            <p
              className="prose"
              dangerouslySetInnerHTML={{
                __html: mdParser.render(jobOpp.responsibilities),
              }}
            />
            <h3 className="-mb-6 text-lg">Requirements</h3>
            <p
              className="prose"
              dangerouslySetInnerHTML={{
                __html: mdParser.render(jobOpp.requirements),
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default JobOppDetails;
