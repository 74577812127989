import React, { useContext, useMemo } from "react";
import { Amplify } from "aws-amplify";
import { ThemeProvider, Authenticator } from "@aws-amplify/ui-react";
import { HashRouter, Switch } from "react-router-dom";
import { CompatRoute, CompatRouter } from "react-router-dom-v5-compat";
import { ToastContainer } from "react-toastify";

import RootProvider, { AuthContext } from "context/providers";
import awsConfig from "./aws-exports";

import { SearchContainer, ModalContainer } from "containers";
import UserSearch from "pages/UserSearch";
import JobOpportunities from "pages/JobOppList";
import JobOppDetails from "pages/JobOppDetails";

import logoImg from "images/headerLogo.png";

import "@aws-amplify/ui-react/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-toggle/style.css";

function urlMatchesLocation(url) {
  if (window.location.href.includes(url)) {
    return url;
  }

  return null;
}

awsConfig.oauth.domain =
  process.env.REACT_APP_COGNITO_HOSTED_DOMAIN || awsConfig.oauth.domain;

const aAcceptabelSignInURLs = awsConfig.oauth.redirectSignIn.split(",");
const aAcceptableSignOutURLs = awsConfig.oauth.redirectSignOut.split(",");

awsConfig.oauth.redirectSignIn = aAcceptabelSignInURLs.find(urlMatchesLocation);
awsConfig.oauth.redirectSignOut =
  aAcceptableSignOutURLs.find(urlMatchesLocation);

Amplify.configure(awsConfig);

const components = {
  SignIn: {
    Header() {
      return (
        <div className="flex justify-center pt-8">
          <img
            className="h-14 w-40"
            src={logoImg}
            alt="torc"
            width={183}
            height={68}
          />
        </div>
      );
    },
  },
};

function PrivateRoute({ children, signOut }) {
  const { isAdmin, isManager } = useContext(AuthContext);
  const loading = useMemo(
    () => isAdmin === undefined && isManager === undefined,
    [isAdmin, isManager]
  );

  if (loading) {
    return (
      <div className="flex justify-center mt-8">
        <span className="loader"></span>
      </div>
    );
  }

  if (!isAdmin && !isManager) {
    return (
      <div className="text-center mt-8">
        <span className="text-red-400">
          You are not authorized to use this service. Please contact
          administrator if you think this is a mistake.
        </span>
        <br />
        <button
          onClick={signOut}
          className="text-blue-300 border-blue-300 border p-8 mt-16 hover:bg-blue-300 hover:text-white"
        >
          Sign out
        </button>
      </div>
    );
  }

  return children;
}

export default function App() {
  return (
    <ThemeProvider>
      <Authenticator
        socialProviders={["google"]}
        hideSignUp={true}
        components={components}
      >
        {({ signOut, user }) => (
          <>
            <RootProvider>
              <SearchContainer>
                <HashRouter>
                  <CompatRouter>
                    <Switch>
                      <CompatRoute
                        path="/"
                        exact
                        render={() => (
                          <PrivateRoute user={user} signOut={signOut}>
                            <JobOpportunities user={user} signOut={signOut} />
                          </PrivateRoute>
                        )}
                        user={user}
                      />
                      <CompatRoute
                        path="/usermanager"
                        exact
                        render={() => (
                          <PrivateRoute user={user} signOut={signOut}>
                            <UserSearch user={user} signOut={signOut} />
                          </PrivateRoute>
                        )}
                        user={user}
                      />
                      <CompatRoute
                        path="/jobOpps/:id/details"
                        exact
                        render={() => (
                          <PrivateRoute user={user} signOut={signOut}>
                            <JobOppDetails user={user} signOut={signOut} />
                          </PrivateRoute>
                        )}
                        user={user}
                      />
                    </Switch>
                  </CompatRouter>
                </HashRouter>
              </SearchContainer>

              <ModalContainer />
            </RootProvider>

            <ToastContainer autoClose={3000} theme="dark" />
          </>
        )}
      </Authenticator>
    </ThemeProvider>
  );
}
