import React, { createContext, useReducer, useEffect } from "react";

import { contextConstants } from "context/constants";
import { contextActions } from "context/actions";
import { contextReducers } from "context/reducers";

export const JobsContext = createContext();

export const JobsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    contextReducers.jobs.reducer,
    contextReducers.jobs.initialState
  );

  const init = async () => {
    dispatch({
      type: contextConstants.jobs.JOB_OPPS_LOADING,
      payload: { isLoading: true },
    });

    const { jobOpps, jobTypes, companyNames } =
      await contextActions.jobs.initializeJobs();

    dispatch({
      type: contextConstants.jobs.JOB_OPPS_LOADED,
      payload: { jobOpps },
    });

    dispatch({
      type: contextConstants.jobs.JOB_TYPES_LOADED,
      payload: {
        jobTypes: Object.keys(jobTypes).sort(),
      },
    });

    dispatch({
      type: contextConstants.jobs.COMPANY_NAMES_LOADED,
      payload: {
        companyNames: Object.keys(companyNames).sort(),
      },
    });
  };

  const initJob = async (jobId) => {
    const jobOpp = await contextActions.jobs.initializeJob(jobId);

    dispatch({
      type: contextConstants.jobs.JOB_OPP_LOADED,
      payload: { jobOpp },
    });
  };

  const updateJob = async (jobId, payload) => {
    await contextActions.jobs.updateJob(jobId, payload);
  };

  const updateJobLocally = async (value, valueKey) => {
    const jobOpp = await contextActions.jobs.updateJobLocally(
      state.jobOpp,
      value,
      valueKey
    );

    dispatch({
      type: contextConstants.jobs.JOB_OPP_LOADED,
      payload: { jobOpp },
    });
  };

  const clearJob = () => {
    dispatch({
      type: contextConstants.jobs.JOB_OPP_CLEARED,
    });
  };

  const updateCompanyFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.COMPANY_FILTER_UPDATED,
      payload: { companyFilter: newValue },
    });
  };

  const updateJobTypeFilter = (newValue) => {
    dispatch({
      type: contextConstants.jobs.JOB_TYPE_FILTER_UPDATED,
      payload: { jobTypeFilter: newValue },
    });
  };

  const clearFilters = () => {
    dispatch({ type: contextConstants.jobs.JOB_FILTERS_CLEARED });
  };

  const jobOpps = {
    jobOpp: state.jobOpp,
    jobs: state.jobOpps,
    companyFilter: state.companyFilter,
    companyNames: state.companyNames,
    jobTypeFilter: state.jobTypeFilter,
    jobTypes: state.jobTypes,
    loadingJobOpp: state.loadingJobOpp,
    isLoading: state.isLoading,
    updateCompanyFilter,
    updateJobTypeFilter,
    initJob,
    updateJob,
    updateJobLocally,
    clearJob,
    clearFilters,
    init,
  };

  useEffect(() => {
    (async () => await init())();
  }, []);

  return (
    <JobsContext.Provider value={jobOpps}>{children}</JobsContext.Provider>
  );
};
