import React, { useMemo } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { JOB_OPPORTUNITY_STATUSES, JOB_APPLICATION_MATCH_STATUS } from "lookup";

import HelpText from "./HelpText";

import previewIcon from "images/preview.png";

export default function JobListingCard({ job }) {
  const matches = useMemo(() => {
    const totals = {
      [JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]: 0,
      [JOB_APPLICATION_MATCH_STATUS.MATCHED]: 0,
      [JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER]: 0,
      [JOB_APPLICATION_MATCH_STATUS.APPLIED]: 0,
      [JOB_APPLICATION_MATCH_STATUS.SKIPPED]: 0,
    };

    job.matches.items.forEach((item) => totals[item.status]++);

    return totals;
  }, [job.matches.items]);

  const jobDuration = (job) => {
    if (job?.jobLengthInWeeks) {
      return `${job.jobLengthInWeeks} weeks`;
    }
    return `${job?.jobLength} months`;
  };

  const statusColor = (() => {
    switch (job.status) {
      case JOB_OPPORTUNITY_STATUSES.ACTIVE:
        return `#5523a2`;
      case JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL:
        return `#e7b73c`;
      default:
        return `black`;
    }
  })();
  const textColor =
    job.status === JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL
      ? "text-black"
      : "text-white";

  const formatJobStatus = (status) => {
    let txt = status;

    switch (status) {
      case JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL:
        txt = "Pending";
        break;
      default:
        txt = status;
    }

    return txt;
  };

  return (
    <div
      className="p-0.5 flex shadow-lg relative font-rubik"
      style={{
        background: `linear-gradient(to right, #83D9BB, #F4D675)`,
      }}
    >
      <div className="bg-white w-full">
        <div className="sm:flex">
          <div
            className="relative -top-0.5 -left-0.5 w-40 flex items-center justify-center max-h-14 h-14"
            style={{ background: statusColor }}
          >
            <span className={classNames("uppercase font-bold", textColor)}>
              {formatJobStatus(job.status)}
            </span>
          </div>

          <div className="ml-6 sm:ml-3 sm:mt-1 mt-8 sm:self-center">
            <div className="font-medium text-grey-800 text-sm">
              <p>
                <span title="Customer company name">
                  {job.organization ||
                    job?.customer?.companyDetails?.name ||
                    job?.customer?.company}{" "}
                  /{" "}
                </span>
                <span title="Customer username">
                  {job.customer?.username} /{" "}
                </span>
                <span title="Customer name">
                  {job.customer?.given_name} {job.customer?.family_name}
                </span>
              </p>
            </div>

            <p className="font-bold text-grey-900 text-xl">{job.title}</p>

            <div className="text-indigo-700 italic font-bold text-sm">
              <p>
                <span>{job.jobType.title} </span>
                <span>{" / "}</span>
                <span>{jobDuration(job)}</span>
                <span>{" / "}</span>
                <span>
                  Hourly Rate: ${job.minRate?.value}
                  &ndash;${job.maxRate?.value}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="absolute top-4 right-4">
          <Link to={`/jobOpps/${job.id}/details`}>
            <img
              src={previewIcon}
              alt="preview"
              title="preview"
              className="w-5 h-3"
            />
          </Link>
        </div>

        <div className="w-full pt-4 pb-6 px-10 flex justify-between flex-wrap gap-4">
          <div className="text-sm flex flex-col items-center text-grey-800">
            <div className="flex items-center">
              <span>Shortlisted</span>

              <HelpText text="Profiles which are shortlisted but no emails/contact made with the talent" />
            </div>

            <span className="font-bold text-lg text-grey-900">
              {matches[JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]}
            </span>
          </div>
          <div className="text-sm flex flex-col items-center text-grey-800">
            <div className="flex items-center">
              <span>Matched</span>

              <HelpText text="Selected profiles on which email has been sent to apply for the job. Haven't applied yet" />
            </div>

            <span className="font-bold text-lg text-grey-900">
              {matches[JOB_APPLICATION_MATCH_STATUS.MATCHED]}
            </span>
          </div>
          <div className="text-sm flex flex-col items-center text-grey-800">
            <div className="flex items-center">
              <span>Rejected</span>

              <HelpText text="Profiles rejected by customer" />
            </div>

            <span className="font-bold text-lg text-grey-900">
              {matches[JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER]}
            </span>
          </div>
          <div className="text-sm flex flex-col items-center text-grey-800">
            <div className="flex items-center">
              <span>Applied</span>

              <HelpText text="Talent currently applied for the job" />
            </div>

            <span className="font-bold text-lg text-grey-900">
              {matches[JOB_APPLICATION_MATCH_STATUS.APPLIED]}
            </span>
          </div>
          <div className="text-sm flex flex-col items-center text-grey-800">
            <div className="flex items-center">
              <span>Skipped</span>

              <HelpText text="Skipped profiles" />
            </div>

            <span className="font-bold text-lg text-grey-900">
              {matches[JOB_APPLICATION_MATCH_STATUS.SKIPPED]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
