import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import SvgIcon from "components/base/SvgIcon";

const Collapsible = ({ children, label, isVisible, onCollapse, className }) => {
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => setVisible(isVisible), [isVisible]);

  const handleCollapse = () => {
    setVisible((prev) => !prev);
    onCollapse(visible);
  };

  return (
    <div className={classNames(`font-rubik ${className}`)}>
      <div
        title={visible ? "close" : "open"}
        className={classNames(
          "flex items-center cursor-pointer opacity-80 transition-all select-none hover:opacity-100 w-min",
          { "!opacity-100": visible }
        )}
        onClick={handleCollapse}
      >
        <SvgIcon
          type="arrowDown"
          className={classNames("transition-all h-5 -rotate-180", {
            "rotate-0": visible,
          })}
        />

        <p className="font-bold ml-2 text-xl whitespace-nowrap text-grey-900">
          {label}
        </p>
      </div>

      <div
        className={classNames("mt-2", {
          "h-0 overflow-hidden": !visible,
          "h-auto": visible,
        })}
      >
        {children}
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  label: PropTypes.string,
  isVisible: PropTypes.bool,
  onCollapse: PropTypes.func,
  className: PropTypes.string,
};

Collapsible.defaultProps = {
  label: "",
  isVisible: false,
  onCollapse: () => {},
  className: "",
};

export default Collapsible;
