import React, { createContext, useReducer, useContext, useMemo } from "react";

import { CUSTOM_PANEL_DEFAULT_OPERATOR, USER_TYPES } from "lookup";

import { contextConstants } from "context/constants";
import { contextActions } from "context/actions";
import { contextReducers } from "context/reducers";
import { JobsContext } from "./JobsProvider";

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const { jobOpp } = useContext(JobsContext);

  const [state, dispatch] = useReducer(
    contextReducers.search.reducer,
    contextReducers.search.initialState
  );

  const defaultFilters = useMemo(
    () => {
      return {
        ...state.searchState,
        range: {},
        toggle: { agreedToTerms: true },
        refinementList: {
          userType: [USER_TYPES.FREELANCER],
          ...(jobOpp.id
            ? { "applications.jobTypeTitle": [jobOpp.jobType.title] }
            : {}),
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobOpp.id]
  );

  const setSearchState = (searchState) => {
    dispatch({
      type: contextConstants.search.SEARCH_STATE_CHANGED,
      payload: { searchState },
    });
  };

  const setHits = (hits) => {
    dispatch({
      type: contextConstants.search.HITS_LOADED,
      payload: { hits },
    });
  };

  const replaceHit = (hit) => {
    const hits = contextActions.search.replaceHit(hit, state.hits);

    setHits(hits);
  };

  const updateHitField = (hitId, fieldKey, value) => {
    const updatedHits = contextActions.search.updateHitFieldByKey(
      state.hits,
      hitId,
      fieldKey,
      value
    );

    const updatedHit = updatedHits.find((hit) => hit.id === hitId);

    dispatch({
      type: contextConstants.search.HITS_LOADED,
      payload: { ...state, hits: updatedHits },
    });

    return updatedHit;
  };

  const updateHit = async (args) => {
    const updatedHit = await contextActions.search.updateHit(args);

    replaceHit(updatedHit);
  };

  const setOperators = (valueKey) => {
    if (Array.isArray(valueKey)) {
      const operators = {};

      valueKey.forEach(
        (el) => (operators[el] = CUSTOM_PANEL_DEFAULT_OPERATOR[el])
      );

      dispatch({
        type: contextConstants.search.OPERATOR_UPDATED,
        payload: { operators },
      });
    } else {
      dispatch({
        type: contextConstants.search.OPERATOR_UPDATED,
        payload: {
          operators: {
            ...state.operators,
            [valueKey]: state.operators[valueKey] === "and" ? "or" : "and",
          },
        },
      });
    }
  };

  const setHideSkipped = (hideSkipped) => {
    dispatch({
      type: contextConstants.search.HIDE_SKIPPED_CHANGED,
      payload: { hideSkipped },
    });
  };

  const clearSearchState = () => {
    setSearchState(defaultFilters);
    setOperators(Object.keys(state.operators));
  };

  const search = {
    searchState: state.searchState,
    hits: state.hits,
    operators: state.operators,
    hideSkipped: state.hideSkipped,
    setSearchState,
    setHits,
    updateHitField,
    updateHit,
    setOperators,
    clearSearchState,
    setHideSkipped,
  };

  return (
    <SearchContext.Provider value={search}>{children}</SearchContext.Provider>
  );
};
