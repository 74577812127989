import React, { useContext, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";

import {
  RefinementList,
  ToggleRefinement,
  NumericMenu,
  DynamicWidgets,
  Panel,
  connectRefinementList,
} from "react-instantsearch-dom";

import { SearchContext } from "context/providers";

import { DEFAULT_RATE_RANGE, USER_TYPES } from "lookup";

import {
  CustomPanel,
  CustomRangeSlider,
  CustomRefinementList,
} from "./components";

const LANG_KEY = "knownLanguages.language";
const LANG_LEVEL_KEY = "knownLanguages.level";

const subtractUnix = (amount, unit) =>
  dayjs().subtract(amount, unit).unix() * 1000;

const CREATED_AT_ITEMS = [
  {
    label: "< 1 day",
    start: subtractUnix(1, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 7 days",
    start: subtractUnix(6, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 30 days",
    start: subtractUnix(29, "days"),
    end: dayjs().unix() * 1000,
  },
];

const LAST_ACTIVITY_ITEMS = [
  {
    label: "< 1 day",
    start: subtractUnix(1, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 7 days",
    start: subtractUnix(6, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 30 days",
    start: subtractUnix(29, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "< 60 days",
    start: subtractUnix(59, "days"),
    end: dayjs().unix() * 1000,
  },
  {
    label: "> 60 days",
    end: dayjs().unix() * 1000,
  },
];

const VirtualRefinementList = connectRefinementList(() => null);

const SearchFilters = () => {
  const [languagesStr, setLanguagesStr] = useState([]);

  const { searchState, clearSearchState, operators, setOperators } =
    useContext(SearchContext);

  const { languages, languagesLevel, shouldCheckLanguages } = useMemo(() => {
    const languages = searchState.refinementList?.[LANG_KEY] || [];
    const languagesLevel = searchState.refinementList?.[LANG_LEVEL_KEY] || [];

    return {
      languages,
      languagesLevel,
      shouldCheckLanguages: !!languages.length && !!languagesLevel.length,
    };
  }, [searchState.refinementList]);

  const definedRate = useMemo(
    () => searchState?.range?.["ratePerHour.value"],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchState?.range?.["ratePerHour.value"]]
  );

  const handleChange = (value, valueKey, targetName) => {
    switch (targetName) {
      case "operators": {
        setOperators(valueKey);
        break;
      }

      default: {
        break;
      }
    }
  };

  const handleLanguageChange = () => {
    if (shouldCheckLanguages) {
      setLanguagesStr(() => {
        const output = [];

        languages.forEach((lang) => {
          languagesLevel.forEach((langLevel) => {
            output.push(`${lang}_${langLevel}`);
          });
        });

        return output;
      });
    } else {
      setLanguagesStr([]);
    }
  };

  useEffect(() => {
    handleLanguageChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages, languagesLevel, shouldCheckLanguages]);

  return (
    <>
      <span
        className="mb-4 text-blue-500 block py-0 w-min whitespace-nowrap cursor-pointer opacity-70 transition-all select-none hover:opacity-100 underline hover:no-underline"
        onClick={clearSearchState}
      >
        Clear Filters
      </span>

      <DynamicWidgets facets={["*"]} maxValuesPerFacet={1000}>
        <CustomPanel
          title="Test Name"
          checked={operators["assessments.testName"] === "and"}
          valueKey="assessments.testName"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="If you would like to filter multiple tests. Ex:  C# and Java. Select OR if you want to see talent with either of the test. Select AND if you would like to see the only talents with both the tests."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <CustomRefinementList
            attribute="assessments.testName"
            placeholder="Search for tests"
            operator={operators["assessments.testName"]}
            limit={15}
            showCount
            searchable
            showMore
            showChip
          />
        </CustomPanel>
        <Panel header="Assessments Final Score">
          <NumericMenu
            attribute="assessments.finalScore"
            items={[
              { label: "<= 25", start: 0, end: 25 },
              { label: "26 <= 50", start: 26, end: 50 },
              { label: "51 <= 75", start: 51, end: 75 },
              { label: ">= 76", start: 76, end: 100 },
              { label: "= 100", start: 100, end: 100 },
            ]}
            transformItems={(items) =>
              items.map((item) => {
                return {
                  ...item,
                  noRefinement: false,
                };
              })
            }
          />
        </Panel>
        <Panel header="Location (Country)">
          <RefinementList
            attribute="location.countryName"
            translations={{
              placeholder: "Search in a specific location",
            }}
            limit={5}
            searchable
            showMore
          />
        </Panel>
        <CustomPanel
          title="Job Role"
          checked={operators["applications.jobTypeTitle"] === "and"}
          valueKey="applications.jobTypeTitle"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="Select OR if you want to see talent with EITHER of the job roles. Select AND if you would like to only see talent with BOTH of the job roles."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <RefinementList
            attribute="applications.jobTypeTitle"
            translations={{
              placeholder: "Search for a specific job role",
            }}
            operator={operators["applications.jobTypeTitle"]}
            searchable
          />
        </CustomPanel>
        <Panel header="Referrer Code">
          <RefinementList
            attribute="referrerCode"
            translations={{
              placeholder: "Search for specific referrer code",
            }}
            limit={5}
            searchable
            showMore
          />
        </Panel>
        <Panel header="% of Profile Completion">
          <NumericMenu
            attribute="profileCompletion"
            items={[
              { label: "<= 25", start: 0, end: 25 },
              { label: "26 <= 50", start: 26, end: 50 },
              { label: "51 <= 75", start: 51, end: 75 },
              { label: ">= 76", start: 76, end: 100 },
              { label: "= 100", start: 100, end: 100 },
            ]}
            transformItems={(items) =>
              items.map((item) => {
                return {
                  ...item,
                  noRefinement: false,
                };
              })
            }
          />
        </Panel>
        <CustomPanel
          title="Skills"
          checked={operators["skills.name"] === "and"}
          valueKey="skills.name"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="If you would like to filter multiple skills. Ex:  C# and Java. Select OR if you want to see talent with either of the skill. Select AND if you would like to see the only talents with both the skills."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <CustomRefinementList
            attribute="skills.name"
            placeholder="Search for skills"
            operator={operators["skills.name"]}
            limit={15}
            showMoreLimit={1000}
            showCount
            searchable
            showMore
            showChip
          />
        </CustomPanel>
        <Panel header="User Type">
          <CustomRefinementList
            attribute="userType"
            predefinedValue={[USER_TYPES.FREELANCER]}
            placeholder="Search for user type"
            showCount
            searchable
            showChip
          />
        </Panel>
        <Panel header="User Status">
          <RefinementList attribute="status" />
        </Panel>
        <Panel header="User Visibility">
          <RefinementList attribute="visibility" />
        </Panel>
        <ToggleRefinement
          attribute="agreedToMarketing"
          label="Agreed to marketing"
          value={false}
          defaultRefinement={false}
        />
        <ToggleRefinement
          attribute="agreedToTerms"
          label="Agreed to terms"
          value={true}
          defaultRefinement={true}
        />
        <CustomPanel
          title="Language"
          checked={operators["knownLanguages.language"] === "and"}
          valueKey="knownLanguages.language"
          leftToggleLabel="OR"
          rightToggleLabel="AND"
          helpText="If you would like to filter multiple languages. Ex:  English and Spanish. Select OR if you want to see talent with either of the language. Select AND if you would like to see the only talents with both the language."
          onChange={(value, valueKey) =>
            handleChange(value, valueKey, "operators")
          }
          toggle
        >
          <CustomRefinementList
            attribute="knownLanguages.language"
            translations={{
              placeholder: "Search for languages",
            }}
            operator={operators["knownLanguages.language"]}
            limit={5}
            showMoreLimit={1000}
            showCount
            showMore
            searchable
          />
        </CustomPanel>
        <Panel header="Language Level">
          <CustomRefinementList
            attribute="knownLanguages.level"
            placeholder="Search for language level"
            showCount={false}
            searchable
          />
        </Panel>
        <Panel header="Availability">
          <RefinementList attribute="availability" />
        </Panel>
        <Panel header="Hourly Rate">
          <CustomRangeSlider
            attribute="ratePerHour.value"
            {...(definedRate?.min && { min: DEFAULT_RATE_RANGE.min })}
            {...(definedRate?.max && { max: DEFAULT_RATE_RANGE.max })}
            displayMin={DEFAULT_RATE_RANGE.min}
            displayMax={DEFAULT_RATE_RANGE.max}
            showInputs
          />
        </Panel>
        <Panel header="Talent source">
          <RefinementList attribute="talentSource" />
        </Panel>
        <Panel header="Last activity date">
          <NumericMenu
            attribute="lastActivityDate_dt"
            items={LAST_ACTIVITY_ITEMS}
            transformItems={(items) => {
              return items.map((item) => {
                return {
                  ...item,
                  noRefinement: false,
                };
              });
            }}
          />
        </Panel>
        <Panel header="Sign Up Date">
          <NumericMenu
            attribute="createdAt_dt"
            items={CREATED_AT_ITEMS}
            transformItems={(items) => {
              return items.map((item) => {
                return {
                  ...item,
                  noRefinement: false,
                };
              });
            }}
          />
        </Panel>
        <VirtualRefinementList
          attribute="knownLanguages_str"
          defaultRefinement={languagesStr}
        />
      </DynamicWidgets>
    </>
  );
};

export default SearchFilters;
