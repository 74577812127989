import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_SUB_STATUS,
} from "lookup";
import { getCardClassName } from "components/UserCardList/helpers/userCard";

import {
  AuthContext,
  JobsContext,
  MatchContext,
  ModalContext,
} from "context/providers";

import HelpText from "components/HelpText";

import {
  HitActionButtons,
  CollapsibleSection,
  MatchActionButtons,
  MatchedDetails,
  ProfileCompletion,
  Status,
  UserContacts,
  UserInfo,
} from "./molecules";

const UserCardResult = ({
  hit,
  showActions,
  showStatusColor,
  collectionKey,
}) => {
  const { showModal } = useContext(ModalContext);
  const { createMatch, updateMatch, removeMatch } = useContext(MatchContext);
  const { jobOpp } = useContext(JobsContext);
  const { isAdmin } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState({
    [JOB_APPLICATION_MATCH_STATUS.MATCHED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SKIPPED]: false,
    [JOB_APPLICATION_MATCH_STATUS.DELETED]: false,
    [JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST]: false,
    [JOB_APPLICATION_MATCH_SUB_STATUS.NULL]: false,
  });

  const { applicationId, jobOpportunityId } = useMemo(() => {
    const app = hit.applications?.find(
      (a) => a.jobTypeId === jobOpp.jobType?.id
    );

    return {
      applicationId: hit?.match?.applicationId || app?.id,
      jobOpportunityId: jobOpp?.id,
    };
  }, [hit, jobOpp]);

  const createNewMatch = async (status) => {
    setIsLoading((prev) => ({ ...prev, [status]: true }));

    try {
      await createMatch(applicationId, jobOpportunityId, status);
    } catch (error) {
      console.log("matchAction error: ", error);
    } finally {
      setIsLoading((prev) => ({ ...prev, [status]: false }));
    }
  };

  const updateExistingMatch = async (args) => {
    setIsLoading((prev) => ({
      ...prev,
      [args.status || args.subStatus]: true,
    }));

    try {
      await updateMatch(applicationId, jobOpportunityId, args);
    } catch (error) {
      console.log("matchAction error: ", error);
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        [args.status || args.subStatus]: false,
      }));
    }
  };

  const removeExistingMatch = async () => {
    const confirmRemove = window.confirm(
      `Are you sure you want to delete ${hit.username}?`
    );

    if (confirmRemove) {
      setIsLoading((prev) => ({
        ...prev,
        [JOB_APPLICATION_MATCH_STATUS.DELETED]: true,
      }));

      try {
        await removeMatch(applicationId, jobOpportunityId);
      } catch (error) {
        console.log("removeExistingMatch error: ", error);
      } finally {
        setIsLoading((prev) => ({
          ...prev,
          [JOB_APPLICATION_MATCH_STATUS.DELETED]: false,
        }));
      }
    }
  };

  return (
    <div
      className={classNames("flex flex-col bg-white font-rubik", {
        ...getCardClassName(hit, showStatusColor),
      })}
    >
      <div className="flex justify-between border border-[#E5E7EB] p-4 box-border">
        <div className="w-[100px]">
          <UserContacts hit={hit} />
        </div>

        <div className="w-[calc(100%_-_294px)]">
          <UserInfo hit={hit} />

          <CollapsibleSection hit={hit} />
        </div>

        <div className="w-[130px] flex flex-col gap-4">
          <ProfileCompletion hit={hit} />

          <button
            className={classNames(
              "transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex items-center justify-center border-2 border-grey-800 text-grey-800 hover:bg-grey-100 box-border h-[36px]"
            )}
            onClick={() =>
              showModal({
                type: "link",
                userId: hit.id,
                applicationId: hit?.match?.applicationId,
                collectionKey,
              })
            }
          >
            <span>Add Link</span>
          </button>

          {isAdmin && (
            <a
              className="transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex justify-center items-center bg-[#302C7F] text-white"
              target="_blank"
              rel="noreferrer"
              href={`https://admin.torc.dev/users/${hit.username}`}
            >
              <span className="w-full text-center">Admin Tool</span>
            </a>
          )}

          <a
            className="transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex justify-center items-center bg-[#0495b7] text-white"
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_SFDC_BASE_URL}?id=${hit.id}`}
          >
            <span className="w-full text-center">View in SFDC</span>
          </a>

          {hit?.reasonsForMatch && (
            <HelpText
              iconType="text"
              iconText="Why Fit?"
              className="flex justify-center"
              iconTextClassName="whitespace-nowrap"
            >
              <ul className="max-w-md space-y-1 text-black font-rubik text-normal list-disc list-inside normal-case">
                {hit.reasonsForMatch.map((el) => {
                  return (
                    <li key={el} className="font-rubik font-normal text-sm">
                      {el}
                    </li>
                  );
                })}
              </ul>
            </HelpText>
          )}
        </div>
      </div>

      <div className="border-t border-grey-900 py-6 bg-[#E9E9E9]">
        {(hit.associatedMatch || hit?.match?.applicationId) && (
          <>
            <div className="pl-[148px]">
              {hit?.match?.applicationId && (
                <p className="mb-3">Matched Details</p>
              )}

              <div className="flex items-start gap-3">
                <div className="grid grid-cols-1 gap-3">
                  <Status
                    matchStatus={
                      hit?.match?.applicationId
                        ? hit.match?.status
                        : hit.associatedMatch.status
                    }
                  />
                  <Status
                    matchStatus={
                      hit?.match?.applicationId
                        ? hit.match?.subStatus
                        : hit.associatedMatch.subStatus
                    }
                  />
                </div>
                {hit?.match?.applicationId && (
                  <MatchedDetails hit={hit} match={hit.match} />
                )}
              </div>
            </div>

            <hr className="border-t border-grey-900 my-6" />
          </>
        )}

        <div className="pl-[148px]">
          {hit?.match?.applicationId ? (
            <MatchActionButtons
              match={hit.match}
              isLoading={isLoading}
              updateMatch={updateExistingMatch}
              removeMatch={removeExistingMatch}
            />
          ) : (
            <HitActionButtons
              hit={hit}
              isLoading={isLoading}
              showActions={showActions}
              matchAction={createNewMatch}
              jobType={jobOpp.jobType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

UserCardResult.propTypes = {
  hit: PropTypes.object.isRequired,
  showActions: PropTypes.bool,
  showStatusColor: PropTypes.bool,
  collectionKey: PropTypes.string,
};

UserCardResult.defaultProps = {
  showActions: false,
  showStatusColor: false,
  collectionKey: "hits",
};

export default UserCardResult;
