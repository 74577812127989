import Base from "./Base.js";

import { getJobOpportunity, listJobOpportunitys } from "api/graphql/queries.js";
import { updateJobOpportunity } from "./graphql/mutations.js";

export default class JobsAPI extends Base {
  list(payload) {
    return this.apiClient.request({ query: listJobOpportunitys, payload });
  }

  get(payload) {
    return this.apiClient.request({ query: getJobOpportunity, payload });
  }

  update(payload) {
    return this.apiClient.request({ query: updateJobOpportunity, payload });
  }
}
