import React, { useContext, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom-v5-compat";
import qs from "query-string";

import { JobsContext } from "context/providers";

import SvgButton from "components/base/SvgButton";
import JobListingCard from "components/JobListingCard";

import logoImg from "images/headerLogo.png";

export default function JobOpportunities({ signOut }) {
  const {
    jobs,
    isLoading,
    companyFilter,
    companyNames,
    jobTypeFilter,
    jobTypes,
    updateCompanyFilter,
    updateJobTypeFilter,
    clearFilters,
    init,
  } = useContext(JobsContext);

  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const clearQs = useCallback(() => {
    navigate(`${pathname}`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const updateQs = useCallback(
    (key, value) => {
      const queryString = qs.stringify({
        ...qs.parse(search),
        [key]: value,
      });

      navigate(`${pathname}?${queryString}`, { replace: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const onCompanyChange = useCallback(
    (value) => {
      updateCompanyFilter(value);
      updateQs("companyFilter", value);
    },
    [updateCompanyFilter, updateQs]
  );

  const onJobTypeChange = useCallback(
    (value) => {
      updateJobTypeFilter(value);
      updateQs("jobTypeFilter", value);
    },
    [updateJobTypeFilter, updateQs]
  );

  const prepareQs = useCallback(() => {
    if (companyFilter && companyFilter !== "All") {
      updateQs("companyFilter", companyFilter);
    }

    if (jobTypeFilter && jobTypeFilter !== "All") {
      updateQs("jobTypeFilter", jobTypeFilter);
    }
  }, [companyFilter, jobTypeFilter, updateQs]);

  const prepareSelectedFilters = useCallback(() => {
    const parsed = qs.parse(search);

    Object.keys(parsed).forEach((key) => {
      switch (key) {
        case "companyFilter":
          const chosenCompany = companyNames.find(
            (company) => company === parsed[key]
          );

          if (chosenCompany) {
            onCompanyChange(parsed[key]);
          }

          break;

        case "jobTypeFilter": {
          const chosenJobType = jobTypes.find((type) => type === parsed[key]);

          if (chosenJobType) {
            onJobTypeChange(parsed[key]);
          }

          break;
        }

        default: {
          break;
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyNames, jobTypes]);

  const handleRefresh = () => {
    clearFilters();
    clearQs();
    init();
  };

  const renderJobs = () => {
    if (companyFilter === "All" && jobTypeFilter === "All") {
      return jobs.map((job) => <JobListingCard key={job.id} job={job} />);
    }

    return jobs.map((job) => {
      const company =
        job?.organization ||
        job?.customer?.companyDetails?.name ||
        job?.customer?.company;

      if (
        (company === companyFilter || companyFilter === "All") &&
        (job?.jobType?.title === jobTypeFilter || jobTypeFilter === "All")
      ) {
        return <JobListingCard key={job.id} job={job} />;
      }

      return null;
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(prepareSelectedFilters, [companyNames, jobTypes]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(prepareQs, []);

  if (isLoading) {
    return (
      <div className="flex justify-center mt-8">
        <span className="loader"></span>
      </div>
    );
  }

  updateCompanyFilter("Randstad")

  return (
    <div className="relative">
      <div className="max-w-3xl mx-auto my-12 flex flex-col gap-8 px-4">
        <div className="flex justify-between">
          <img
            className="h-14 w-40"
            src={logoImg}
            alt="torc"
            width={183}
            height={68}
          />
          <div>
            <Link
              to="/usermanager"
              className="text-orange-300 border-orange-300 border p-4 hover:bg-orange-300 hover:text-white mr-8"
            >
              Search
            </Link>
            <button
              className="text-blue-300 border-blue-300 border p-3 hover:bg-blue-300 hover:text-white"
              onClick={signOut}
            >
              Sign out
            </button>
          </div>
        </div>
        <div className="w-full flex justify-center items-center">
          <h3 className="text-center text-2xl font-bold mr-2">
            Active and Pending Jobs
          </h3>

          <SvgButton
            icon="refresh"
            className="w-[20px]"
            title="Refresh Jobs List"
            onClick={handleRefresh}
          />
        </div>
        <div className="flex justify-between">
          <div className="hidden">
            <select
              value={companyFilter}
              className="block mt-2 border rounded border-black w-full p-1"
              onChange={(e) => onCompanyChange(e.target.value)}
            >
              <option value="All">All</option>
              {companyNames.map((name) => (
                <option value={name} key={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="font-bold">Job Roles:</label>
            <div className="block mt-2">
              <select
                value={jobTypeFilter}
                className="block mt-2 border rounded border-black w-full p-1"
                onChange={(e) => onJobTypeChange(e.target.value)}
              >
                <option value="All">All</option>
                {jobTypes.map((jobType, i) => (
                  <option value={jobType} key={`jobtype=${i}`}>
                    {jobType}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {renderJobs()}
      </div>
    </div>
  );
}
