export const JOB_OPPORTUNITY_STATUSES = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  CLOSED: "CLOSED",
  DELETED: "DELETED",
  DRAFT: "DRAFT",
  PENDINGAPPROVAL: "PENDINGAPPROVAL",
};

export const JOB_OPPORTUNITY_TIME_COMMITMENT = {
  FULLTIME: "FULLTIME",
  PARTTIME: "PARTTIME",
};

export const SOCIAL_LINK_TYPES = {
  BADGR: "BADGR",
  CALENDAR: "CALENDAR",
  DEV: "DEV",
  FACEBOOK: "FACEBOOK",
  GITHUB: "GITHUB",
  HASHNODE: "HASHNODE",
  INSTAGRAM: "INSTAGRAM",
  LINKEDIN: "LINKEDIN",
  PORTFOLIO: "PORTFOLIO",
  STACKOVERFLOW: "STACKOVERFLOW",
  TWITTER: "TWITTER",
};

export const CUSTOM_LINK_TYPES = {
  EXTERNALLINK: "EXTERNALLINK",
  GENERAL: "GENERAL",
  RESULT: "RESULT",
  RESUME: "RESUME",
  OTHER: "OTHER",
};

export const JOB_APPLICATION_MATCH_STATUS_FORMATTED = {
  ACCEPTED: "Accepted",
  APPLIED: "Applied",
  MATCHED: "Matched",
  PASSEDON: "Passed On",
  REJECTEDBYCUSTOMER: "Rejected (Customer)",
  REJECTEDBYMEMBER: "Rejected (Member)",
  SHORTLISTED: "Shortlisted",
  MOREINFO: "More Info",
  FINALIST: "Finalist",
};

export const JOB_APPLICATION_MATCH_STATUS = {
  ACCEPTED: "ACCEPTED",
  APPLIED: "APPLIED",
  MATCHED: "MATCHED",
  PASSEDON: "PASSEDON",
  REJECTEDBYCUSTOMER: "REJECTEDBYCUSTOMER",
  REJECTEDBYMEMBER: "REJECTEDBYMEMBER",
  SHORTLISTED: "SHORTLISTED",
  MOREINFO: "MOREINFO",
  SKIPPED: "SKIPPED",
  DELETED: "DELETED",
};

export const JOB_APPLICATION_MATCH_SUB_STATUS = {
  FINALIST: "FINALIST",
  NULL: null,
};
export const JOB_APPLICATION_MATCH_SUB_STATUS_FORMATTED = {
  FINALIST: "Only Finalists",
};

export const CUSTOM_LINK_TITLES_BY_TYPE = {
  [CUSTOM_LINK_TYPES.EXTERNALLINK]: "External link",
  [CUSTOM_LINK_TYPES.GENERAL]: "General link",
  [CUSTOM_LINK_TYPES.OTHER]: "Other link",
  [CUSTOM_LINK_TYPES.RESULT]: "Result link",
  [CUSTOM_LINK_TYPES.RESUME]: "Resume link",
};

export const USER_TYPES = {
  FREELANCER: "FREELANCER",
  CUSTOMER: "CUSTOMER",
  ADMIN: "ADMIN",
};

export const CUSTOM_PANEL_DEFAULT_OPERATOR = {
  "skills.name": "and",
  "applications.jobTypeTitle": "or",
  "knownLanguages.language": "and",
  "assessments.testName": "and",
};

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  SUSPENDED: "SUSPENDED",
};

export const DEFAULT_RATE_RANGE = { min: 0, max: 200 };
