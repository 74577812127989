import React from "react";
import { connectRange } from "react-instantsearch-dom";
import PropTypes from "prop-types";
import Rheostat from "rheostat";

import { DEFAULT_RATE_RANGE } from "lookup";

import Input from "components/base/Input";

import "./index.css";

const RangeSlider = ({
  refine,
  currentRefinement,
  showInputs,
  displayMin,
  displayMax,
  min,
  max,
}) => {
  const onChange = ({ values: [newMin, newMax] }) => {
    const payload = { min: newMin, max: newMax };
    const isValid =
      !isNaN(payload.min) &&
      !isNaN(payload.max) &&
      (currentRefinement.min !== payload.min ||
        currentRefinement.max !== payload.max);

    if (isValid) {
      refine({ ...payload });
    }
  };

  const handleInputChange = ({ value, valueKey }, validate) => {
    let payload = { ...currentRefinement, [valueKey]: +value };
    let isValid = !isNaN(+value) && +value >= min && +value <= max;

    if (!isValid) {
      return;
    }

    if (valueKey === "min" && payload.min > payload.max) {
      payload.max = payload.min;
    }

    if (valueKey === "max" && payload.max < payload.min) {
      if (!validate) {
        return;
      }

      value ? (payload.min = payload.max) : (payload.max = payload.min);
    }
    refine({ ...payload });
  };

  return (
    <div>
      {showInputs && (
        <div className="grid grid-cols-2 gap-6">
          <Input
            value={`${currentRefinement.min}`}
            valueKey="min"
            label="Min rate"
            placeholder="Type min value"
            onChange={handleInputChange}
          />

          <Input
            value={`${currentRefinement.max}`}
            valueKey="max"
            label="Max rate"
            placeholder="Type max value"
            onChange={handleInputChange}
            onBlur={(e) => handleInputChange(e, true)}
          />
        </div>
      )}

      <Rheostat
        min={min || displayMin}
        max={max || displayMax}
        values={[currentRefinement.min, currentRefinement.max]}
        onChange={onChange}
      />

      <div className="rheostat-values">
        <div>{`$${currentRefinement.min}`}</div>
        <div>{`$${currentRefinement.max}`}</div>
      </div>
    </div>
  );
};

const CustomRangeSlider = connectRange(RangeSlider);

RangeSlider.propTypes = {
  refine: PropTypes.func.isRequired,
  currentRefinement: PropTypes.object.isRequired,
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([undefined])]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([undefined])]),
};

RangeSlider.defaultProps = {
  min: undefined,
  max: undefined,
  displayMin: DEFAULT_RATE_RANGE.min,
  displayMax: DEFAULT_RATE_RANGE.max,
};

export default CustomRangeSlider;
