import { cloneDeep } from "lodash";
import { SOCIAL_LINK_TYPES, USER_STATUS } from "lookup";

const mapMatch = (match) => {
  return {
    match,
    ...match.application.user,
    isDeleting: false,
    isMatching: false,
  };
};

export const mapMatchesToHit = (matches, status, excludedStatuses = []) => {
  const clonedMatches = cloneDeep(matches);
  const filteredMatches = clonedMatches.filter((m) => {
    if (!m.application) {
      return false;
    }

    if (m.application?.user?.status !== USER_STATUS.ACTIVE) {
      return false;
    }

    m.application.user.socialLinks = m.application.user?.socialLinks
      ? m.application.user.socialLinks.filter(
          (s) => !!s.value && s.type !== SOCIAL_LINK_TYPES.CALENDAR
        )
      : [];

    if (m.application.user.ratings?.torc) {
      try {
        m.application.user.ratings.torc = JSON.parse(
          m.application.user.ratings.torc
        );
      } catch (err) {
        console.log(
          `${m.application.user.username} does not have valid ratings. Cannot parse JSON.`
        );
      }
    }

    if (!!status) {
      return m.status === status;
    }

    return !excludedStatuses.includes(m.status);
  });

  const finalMatches = filteredMatches.map(mapMatch);

  return finalMatches;
};
