import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { SearchBox } from "react-instantsearch-dom";
import { useNavigate } from "react-router-dom-v5-compat";

import { JobsContext, MatchContext, SearchContext } from "context/providers";
import { getCustomerName } from "utils/helpers/jobOppDetails";
import { USER_TYPES } from "lookup";

import SearchFilters from "components/SearchFilters";
import ApplicationsTabs from "./components/ApplicationsTabs";
import Button from "components/Button";
import SavedSearches from "components/SavedSearches";
import UserCardList from "components/UserCardList";
import DownloadResults from "components/DownloadResults";
import { Chip } from "components/SearchFilters/components";

import logoImg from "images/headerLogo.png";

// https://stackoverflow.com/a/65050864/2104976
// Each widget is responsibile for its own state
// We cannot set the default value for job title
// if the refinement list does not exist
// But we don't want to show the refinement list for job title
// So we are creating this "virtual" refinement list

const Applications = ({ signOut }) => {
  const { jobOpp, clearJob } = useContext(JobsContext);
  const { searchState, setSearchState, clearSearchState } =
    useContext(SearchContext);

  const { clearMatches } = useContext(MatchContext);

  const navigate = useNavigate();

  const isJobPage = useMemo(() => !!jobOpp.id, [jobOpp]);

  const definedSkills = useMemo(
    () => searchState.refinementList?.["skills.name"] || [],
    [searchState.refinementList]
  );

  const goTo = () => {
    navigate("/", { replace: true });
  };

  const setSkills = (skills) => {
    const finalState = {
      ...searchState,
      refinementList: { ...searchState.refinementList, "skills.name": skills },
    };

    setSearchState(finalState);
  };

  const handleAddDefinedSkill = (skill) => {
    const skills = Array.from(new Set([...definedSkills, skill]));

    setSkills(skills);
  };

  const handleDeleteDefinedSkill = (skill) => {
    const skills = definedSkills.filter((el) => el !== skill);

    setSkills(skills);
  };

  const prepareInitialJobState = () => {
    const definedRate = {
      min: (jobOpp.minRate.value / 1.5).toFixed(0),
      max: (jobOpp.maxRate.value / 1.5).toFixed(0),
    };

    const finalState = {
      ...searchState,
      range: {
        "ratePerHour.value": definedRate,
      },
      refinementList: {
        userType: [USER_TYPES.FREELANCER],
        availability: [jobOpp.timeCommitment, "OPENTOOFFERS"],
        "skills.name": jobOpp.skills.map((skill) => skill.name),
        "applications.jobTypeTitle": [jobOpp.jobType.title],
      },
    };

    setSearchState(finalState);
  };

  const prepareInitialUsersState = () => {
    const finalState = {
      ...searchState,
      refinementList: {
        userType: [USER_TYPES.FREELANCER],
      },
    };

    setSearchState(finalState);
  };

  useEffect(() => {
    if (isJobPage) {
      prepareInitialJobState();
    } else {
      prepareInitialUsersState();
    }

    return () => {
      clearJob();
      clearMatches();
      clearSearchState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full flex flex-column">
      <div className="w-3/12 p-4 mt-4">
        <div>
          <Button onClick={goTo} className="mb-4">
            Job List
          </Button>

          <SavedSearches />
        </div>

        <SearchFilters />
      </div>

      <div className="w-6/12 flex">
        <div className="mx-auto w-11/12">
          <div className="flex flex-col">
            <div className="flex justify-between items-center w-full">
              <img
                className="mr-8 h-14 w-40"
                src={logoImg}
                alt="torc"
                width={183}
                height={68}
              />

              <SearchBox
                className="w-full"
                translations={{
                  placeholder: "Search for users",
                }}
                autoFocus
              />
            </div>

            {isJobPage && (
              <div className="mb-8">
                <h1 className="text-4xl font-nexa font-bold mb-2">
                  {jobOpp.title}
                </h1>

                <p className="font-bold">
                  Customer Name:{" "}
                  <span className="font-light">{getCustomerName(jobOpp)}</span>
                </p>

                <p className="mt-7 mb-2 font-medium">Required skills:</p>

                <div className="flex flex-wrap gap-1">
                  {jobOpp.skills?.map((skill) => {
                    const isActive = definedSkills.includes(skill.name);

                    return (
                      <Chip
                        key={skill.name}
                        value={skill.name}
                        isActive={isActive}
                        onClose={() => handleDeleteDefinedSkill(skill.name)}
                        {...(!isActive && {
                          onClick: () => handleAddDefinedSkill(skill.name),
                        })}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          {isJobPage ? (
            <ApplicationsTabs />
          ) : (
            <UserCardList collectionKey="hits" pagination stats />
          )}
        </div>
      </div>

      <div className="w-3/12">
        <DownloadResults />

        <button
          onClick={signOut}
          className="text-blue-300 border-blue-300 border p-4 hover:bg-blue-300 hover:text-white mt-8"
        >
          Sign out
        </button>
      </div>
    </div>
  );
};

Applications.propTypes = {
  signOut: PropTypes.func.isRequired,
};

export default Applications;
