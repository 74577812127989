import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { capitalize } from "lodash";
import { nanoid } from "nanoid";
import dayjs from "dayjs";

import {
  getIconForSocialType,
  getSocialProfileUrl,
  mapSkills,
} from "components/UserCardList/helpers/userCard";

import {
  CUSTOM_LINK_TITLES_BY_TYPE,
  CUSTOM_LINK_TYPES,
  JOB_APPLICATION_MATCH_STATUS_FORMATTED,
} from "lookup";

import { JobsContext } from "context/providers";

import Collapsible from "components/Collapsible";
import SvgIcon from "components/base/SvgIcon";
import { Chip } from "components/SearchFilters/components";

const sortByStatus = {
  ACCEPTED: 1,
  SHORTLISTED: 2,
  APPLIED: 3,
  MOREINFO: 4,
  MATCHED: 5,
  REJECTEDBYCUSTOMER: 6,
  REJECTEDBYMEMBER: 7,
  PASSEDON: 8,
};

const CollapsibleSection = ({ hit }) => {
  const [seeAssessments, setSeeAssessments] = useState(false);
  const [seeMatches, setSeeMatches] = useState(false);

  const { jobOpp } = useContext(JobsContext);

  const mappedSkills = useMemo(
    () => mapSkills(jobOpp.skills || [], hit.skills || []),
    [jobOpp.skills, hit.skills]
  );

  const matchedJobs = useMemo(() => {
    const matches = [];

    for (let app of hit?.applications ?? []) {
      if (app?.matches?.length > 0) {
        matches.push(...app.matches);
      }
    }

    return matches.sort(
      (a, b) => sortByStatus[a.status] - sortByStatus[b.status]
    );
  }, [hit]);

  const assessments = useMemo(() => {
    return hit.assessments || [];
  }, [hit]);

  const getJobName = (jobOpp) => {
    return jobOpp
      ? jobOpp.status === "FULFILLED"
        ? `${jobOpp.title} (${jobOpp.status?.toLowerCase()})`
        : jobOpp.title || "-"
      : "-";
  };

  const formatMatchJobStatus = (status) => {
    const formattedStatus = JOB_APPLICATION_MATCH_STATUS_FORMATTED[status];
    return formattedStatus ?? capitalize(status);
  };

  const prepareScore = (item) => {
    return `${item.finalScore || 0} (Code - ${item.codeScore || 0}, MCQ - ${
      item.multipleChoiceScore || 0
    })`;
  };

  const renderMatchedJobs = (items) => {
    return items.map((job, index) => {
      const jobName = getJobName(job.jobOpportunity);

      return (
        <div
          className="flex flex-col font-rubik-medium text-sm w-full"
          key={job?.applicationId + "-" + job?.jobOpportunityId}
        >
          {index > 0 && <hr className="border-t my-1 w-full" />}

          <div className="flex space-x-2.5">
            <span className="flex-1">{formatMatchJobStatus(job.status)}</span>

            <span className="flex-1">
              {dayjs(job.updatedAt).format("MM DD YYYY").split(" ").join("/")}
            </span>

            <span
              className="flex-1 whitespace-nowrap overflow-ellipsis overflow-hidden"
              title={jobName}
            >
              {jobName}
            </span>
            <span
              className="flex-1 whitespace-nowrap overflow-ellipsis overflow-hidden"
              title={job.jobOpportunity?.organization}
            >
              {job.jobOpportunity?.organization}
            </span>
          </div>
        </div>
      );
    });
  };

  const renderAssessments = (items) => {
    return items.map((assessment, index) => {
      return (
        <div
          className="flex flex-col font-rubik-medium text-sm w-full"
          key={assessment.id}
        >
          {index > 0 && <hr className="border-t my-1 w-full" />}

          <div className="flex gap-2 justify-between">
            <a
              href={assessment.reportLink}
              target="_blank"
              rel="noreferrer"
              title={assessment.testName}
              className={classNames(
                "min-w-[50%] max-w-[50%] whitespace-nowrap overflow-ellipsis overflow-hidden",
                { "text-blue-500": !!assessment.reportLink }
              )}
            >
              {assessment.testName}
            </a>

            <p className="w-1/2 whitespace-nowrap overflow-ellipsis overflow-hidden">
              {assessment.status}
            </p>

            <p
              title={prepareScore(assessment)}
              className="w-auto whitespace-nowrap"
            >
              {assessment.finalScore}
            </p>
          </div>
        </div>
      );
    });
  };

  const getSKillTitle = (skill) => {
    if (skill.experience) {
      return `${skill.name}: ${capitalize(skill.experience)}`;
    }

    return skill.name;
  };

  const renderTorcRatings = (rating) => {
    const rows = rating.skills.map((skill, index) => {
      return (
        <div
          className="flex flex-col font-rubik-medium text-sm w-full"
          key={skill.id}
        >
          {index > 0 && <hr className="border-t my-1 w-full" />}

          <div className="flex space-x-2.5">
            <span className="flex-1">{skill.name}</span>

            <span className="flex-1">{skill.rating * 100} %</span>
          </div>
        </div>
      );
    });

    rows.push(
      <div
        className="flex flex-col font-rubik-medium text-sm w-full"
        key="overall"
      >
        <hr className="border-t my-1 w-full" />

        <div className="flex space-x-2.5">
          <span className="flex-1">Overall</span>

          <span className="flex-1">{rating.overall * 100} %</span>
        </div>
      </div>
    );

    return rows;
  };

  return (
    <div className="w-full flex flex-col gap-4 mt-6">
      {!!hit.socialLinks?.length && (
        <Collapsible label="Social links">
          <div className="flex gap-x-3">
            {hit.socialLinks.map((s) => {
              return (
                <a
                  className="flex transform transition duration-500 hover:scale-110"
                  href={getSocialProfileUrl(s.type, s.value)}
                  target="_blank"
                  rel="noreferrer"
                  key={s.type}
                >
                  <SvgIcon type={getIconForSocialType(s.type)} fill="#202021" />
                </a>
              );
            })}
          </div>
        </Collapsible>
      )}

      {hit.otherLinks && (
        <Collapsible label="User links">
          {hit.otherLinks && (
            <div className="flex flex-wrap gap-x-3 gap-y-1">
              {hit.otherLinks.map((link) => {
                const { name, description, value, type } = link;

                return (
                  <a
                    key={nanoid()}
                    href={value}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer flex"
                    title={`${CUSTOM_LINK_TITLES_BY_TYPE[type]}, ${name}${
                      description && ", " + description
                    }`}
                  >
                    <SvgIcon
                      type={CUSTOM_LINK_TYPES[type]}
                      className="transform transition duration-500 scale-90 hover:scale-100 min-w-[25px] w-[25px] mr-1"
                    />
                    {name}
                  </a>
                );
              })}
            </div>
          )}
        </Collapsible>
      )}

      {!!mappedSkills.length && (
        <Collapsible label="Skills" isVisible>
          <div className="flex flex-wrap gap-2">
            {mappedSkills.map((skill) => (
              <Chip
                key={`modal-view-${skill.id}`}
                value={getSKillTitle(skill)}
                className={classNames({
                  "border-yellow-500 text-slate-400 font-medium":
                    !skill.isMatched,
                })}
              />
            ))}
          </div>
        </Collapsible>
      )}

      {!!matchedJobs.length && (
        <Collapsible label="Matched Jobs">
          {renderMatchedJobs(matchedJobs?.slice(0, 3) || [])}

          {matchedJobs.length > 3 && (
            <div className="relative max-w-xs w-full mt-4">
              <button
                className="text-blue hover:text-blue-600 text-sm"
                onMouseOver={() => setSeeMatches(true)}
              >
                See all Matches
              </button>

              {seeMatches && (
                <div
                  className="h-fit max-h-96 w-fit bg-white p-4 overflow-auto rounded-lg absolute -top-1 shadow-lg z-30"
                  onMouseLeave={() => setSeeMatches(false)}
                >
                  <div className="flex flex-wrap">
                    {renderMatchedJobs(matchedJobs || [])}
                  </div>
                </div>
              )}
            </div>
          )}
        </Collapsible>
      )}

      {!!assessments.length && (
        <Collapsible label="Assessments">
          {renderAssessments(assessments?.slice(0, 3) || [])}

          {assessments.length > 3 && (
            <div className="relative w-full mt-4">
              <button
                className="text-blue hover:text-blue-600 text-sm"
                onMouseOver={() => setSeeAssessments(true)}
              >
                See all Assessments
              </button>
              {seeAssessments && (
                <div
                  className="h-fit max-h-96 min-w-full bg-white p-4 rounded-lg absolute -top-1 shadow-lg z-30"
                  onMouseLeave={() => setSeeAssessments(false)}
                >
                  <div className="flex flex-wrap">
                    {renderAssessments(assessments || [])}
                  </div>
                </div>
              )}
            </div>
          )}
        </Collapsible>
      )}

      {!!hit.ratings?.torc && (
        <Collapsible label="Torc Ratings">
          {renderTorcRatings(hit.ratings.torc)}
        </Collapsible>
      )}
    </div>
  );
};

CollapsibleSection.propTypes = {
  hit: PropTypes.object,
};

CollapsibleSection.defaultPropt = {
  hit: {},
};

export default CollapsibleSection;
