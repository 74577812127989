import { cloneDeep, set } from "lodash";

import { JOB_APPLICATION_MATCH_STATUS } from "lookup";
import api from "apiSingleton";

const ALLOWED_STATUSES = [
  JOB_APPLICATION_MATCH_STATUS.MATCHED,
  JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
  JOB_APPLICATION_MATCH_STATUS.MOREINFO,
  JOB_APPLICATION_MATCH_STATUS.APPLIED,
  JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER,
  JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER,
  JOB_APPLICATION_MATCH_STATUS.ACCEPTED,
  JOB_APPLICATION_MATCH_STATUS.SKIPPED,
];

export function initMatches(jobOpp) {
  if (jobOpp?.matches?.items) {
    const matches = jobOpp.matches?.items
      ?.map((applicant) => {
        const output = { ...applicant };

        if (applicant?.application?.user?.username) {
          output.username = applicant.application.user.username;
        }

        return output;
      })
      .filter((el) => ALLOWED_STATUSES.includes(el.status));

    return matches;
  }

  return [];
}

export async function initBestFit(jobOpp) {
  const autoMatches = await api.match.listBestFit({
    input: { jobOpportunityId: jobOpp.id },
  });

  const hits = JSON.parse(autoMatches.data?.getAutoMatches?.hits || "[]");

  return hits;
}

export async function createMatch(applicationId, jobOpportunityId, status) {
  try {
    await api.match.create({
      input: {
        applicationId,
        jobOpportunityId,
        status,
      },
    });
  } catch (error) {
    console.warn(`createMatch error: ${error}`);
  }
}

export async function updateMatch(args) {
  try {
    const resp = await api.match.update({
      input: {
        ...args,
      },
    });

    return resp.data?.updateMatch || {};
  } catch (error) {
    console.warn(`updateMatch error: ${error}`);
  }
}

export async function removeMatch(applicationId, jobOpportunityId) {
  try {
    await api.match.delete({
      input: {
        applicationId,
        jobOpportunityId,
      },
    });
  } catch (error) {
    console.log("removeMatch error: ", error);
  }
}

export function updateMatchFieldLocally(
  applicationId,
  fieldKey,
  value,
  matches
) {
  const clonedMatches = cloneDeep(matches);
  const matchedIndex = clonedMatches.findIndex(
    (el) => el.applicationId === applicationId
  );
  const newMatchedUser = clonedMatches[matchedIndex];

  if (newMatchedUser) {
    set(newMatchedUser, fieldKey, value);

    clonedMatches[matchedIndex] = { ...newMatchedUser };
  }

  return clonedMatches;
}

export function updateFitFieldLocally(id, fieldKey, value, bestFit) {
  const clonedFit = cloneDeep(bestFit);
  const fitIndex = clonedFit.findIndex((el) => el.id === id);
  const newFit = clonedFit[fitIndex];

  if (newFit) {
    set(newFit, fieldKey, value);

    clonedFit[fitIndex] = { ...newFit };
  }

  return clonedFit;
}

export function removeMatchLocally(applicationId, matches) {
  return matches.filter((el) => el.applicationId !== applicationId);
}

export function replaceMatchLocally(match, matches) {
  const clonedMatches = cloneDeep(matches);
  const existingMatchIndex = clonedMatches.findIndex(
    (el) => el.applicationId === match.applicationId
  );

  clonedMatches[existingMatchIndex] = match;

  return clonedMatches;
}
