import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";

import SvgButton from "components/base/SvgButton";
import ConfirmModal from "./confirmModal";
import FormModal from "./formModal";

/*
  Difference between this modal and the job modal, is this one does not slide in from the side.
  This modal is designed to be a generic modal layer. You can customize what gets rendered 
  via it's children
*/

/**
 *
 * @param Children: the modal requires the use of a child component
 * @param onClose state of the modal should be hoisted in the component
 *  that renders this modal
 * @returns
 */
const Modal = ({
  children,
  isVisible,
  title,
  subTitle,
  className,
  onClose,
}) => {
  const modalRef = useRef();

  const handleKeyPress = ({ keyCode }) => {
    if (keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keyup", handleKeyPress);
    }

    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keyup", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  function useCloseModalOnOffClick(ref, isVisible) {
    useEffect(() => {
      //checks for clicks outside of the content of the modal
      function handleClickOutside(event) {
        const modalContent = ref.current.children[0];

        if (ref.current && !modalContent.children[0].contains(event.target)) {
          onClose();
        }
      }

      // Bind the event listener
      if (isVisible) {
        document.addEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, isVisible]);
  }

  useCloseModalOnOffClick(modalRef, isVisible);

  return createPortal(
    <div
      className={classNames(
        "fixed inset-0 w-screen h-screen z-50 bg-black/40 flex items-center justify-center opacity-0 pointer-events-none transition-all",
        { "opacity-100 pointer-events-auto": isVisible }
      )}
      ref={modalRef}
    >
      <div
        className={classNames(
          "w-full flex items-center justify-center transition-all transform -translate-y-2",
          {
            "translate-y-0": isVisible,
          }
        )}
      >
        <div
          className={classNames(
            "lg:w-6/12 w-10/12 h-6/12 max-h-[60vh] bg-white rounded overflow-y-scroll overflow-x-visible",
            className
          )}
        >
          <div className="w-full flex justify-between items-start px-8 py-4 mb-4 border-b-2">
            <div className="mr-2">
              <div>
                {!!title && <p className="text-2xl font-bold">{title}</p>}

                {!!subTitle && (
                  <p className="font-normal text-base">{subTitle}</p>
                )}
              </div>
            </div>

            <SvgButton icon="cross" onClick={onClose} />
          </div>

          <div className="px-8 pb-8">{children}</div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;

export { ConfirmModal, FormModal };
