import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import SvgIcon from "../SvgIcon";

const SvgButton = ({ icon, onClick, className, isLoading, iconProps }) => {
  return (
    <button
      type="button"
      className={classNames(
        `min-w-[35px] min-h-[35px] max-w-[35px] max-h-[35px] p-[5px] box-border hover:bg-gray-300 transition-all rounded-full flex justify-center items-center relative ${className}`,
        { "pointer-events-none": isLoading }
      )}
      onClick={onClick}
    >
      <SvgIcon
        type={icon}
        className="flex justify-center items-center w-full"
        {...iconProps}
      />

      {isLoading && (
        <div className="loader absolute !w-[25px] !h-[25px] !border" />
      )}
    </button>
  );
};

SvgButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  iconProps: PropTypes.object,
};

SvgButton.defaultProps = {
  onClick: () => {},
  className: "",
  isLoading: false,
  iconProps: {},
};

export default SvgButton;
