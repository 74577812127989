import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_SUB_STATUS,
} from "lookup";

import verifiedTick from "images/verified-tick.png";
import SvgIcon from "components/base/SvgIcon/SvgIcon";

const DEFAULT_STYLES =
  "flex justify-center w-min px-2 items-center text-sm font-bold font-rubik text-grey-900 whitespace-nowrap border-l-4";

const Status = ({ matchStatus }) => {
  const renderStatus = (status) => {
    switch (status) {
      case JOB_APPLICATION_MATCH_STATUS.APPLIED: {
        return (
          <div className={classNames(DEFAULT_STYLES, "border-blue-800")}>
            <p>Applied</p>
          </div>
        );
      }
      case JOB_APPLICATION_MATCH_STATUS.ACCEPTED: {
        return (
          <div className={classNames(DEFAULT_STYLES, "!text-grey-900")}>
            <img src={verifiedTick} alt="verified" className="w-6 mr-2" />
            <p>Hired</p>
          </div>
        );
      }
      case JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER: {
        return (
          <div className={classNames(DEFAULT_STYLES, "border-red-600")}>
            <p>Rejected by Customer</p>
          </div>
        );
      }
      case JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER: {
        return (
          <div className={classNames(DEFAULT_STYLES, "border-red-600")}>
            <p>Rejected by Member</p>
          </div>
        );
      }
      case JOB_APPLICATION_MATCH_STATUS.MOREINFO: {
        return (
          <div className={classNames(DEFAULT_STYLES, "border-electricBlue")}>
            <p>Info Requested</p>
          </div>
        );
      }
      case JOB_APPLICATION_MATCH_STATUS.MATCHED: {
        return (
          <div
            className={classNames(
              DEFAULT_STYLES,
              "border-l-4 border-green-500"
            )}
          >
            <p>Matched</p>
          </div>
        );
      }
      case JOB_APPLICATION_MATCH_STATUS.SHORTLISTED: {
        return (
          <div className={classNames(DEFAULT_STYLES, "border-blue-500")}>
            <p>Shortlisted</p>
          </div>
        );
      }
      case JOB_APPLICATION_MATCH_STATUS.SKIPPED: {
        return (
          <div className={classNames(DEFAULT_STYLES, "border-orange-500")}>
            <p>Skipped</p>
          </div>
        );
      }
      case JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST: {
        return (
          <div
            className={classNames(DEFAULT_STYLES, "!text-grey-900 ml-[-15px]")}
          >
            <SvgIcon type={"checkBadge"} className="h-5 w-5 mr-1" />
            <p>Finalist</p>
          </div>
        );
      }
      default:
        return <></>;
    }
  };

  if (!matchStatus) {
    return null;
  }

  return <>{renderStatus(matchStatus)}</>;
};

Status.propTypes = {
  matchStatus: PropTypes.string,
};

Status.defaultProps = {
  matchStatus: "",
};

export default Status;
