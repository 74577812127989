import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { nanoid } from "nanoid";

import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from "@mui/base/TablePaginationUnstyled";

const Root = styled("div")`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th,
  tr {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #ddd;
  }
`;

const CustomTablePagination = styled(TablePaginationUnstyled)`
  & .${classes.toolbar} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.spacer} {
    display: none;
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.25rem;
  }
`;

const TableWithPagination = ({ header, rows, currentPage, rowsPerPage }) => {
  const [page, setPage] = useState(currentPage);
  const [perPage, setPerPage] = useState(rowsPerPage);

  const colSpan = useMemo(() => header.length, [header]);

  const emptyRows = useMemo(() => {
    return page > 0 ? Math.max(0, (1 + page) * perPage - rows.length) : 0;
  }, [rows, page, perPage]);

  const finalRows = useMemo(() => {
    return rows.length
      ? rows.slice(page * perPage, page * perPage + perPage)
      : [{ title: "No records to display" }];
  }, [rows, page, perPage]);

  const totalPages = useMemo(
    () => rows.length / perPage + 1,
    [rows.length, perPage]
  );

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    setPage(currentPage);
    setPerPage(rowsPerPage);
  }, [currentPage, rowsPerPage]);

  return (
    <Root>
      <table>
        <thead>
          <tr>
            {header.map((head) => (
              <th key={nanoid()}>{head}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {finalRows.map((row) => {
            return (
              <tr key={nanoid()} className="whitespace-nowrap">
                {Object.values(row).map((cell) => {
                  return <td key={nanoid()}>{cell}</td>;
                })}
              </tr>
            );
          })}

          {emptyRows > 0 && (
            <tr style={{ height: 41 * emptyRows }}>
              <td colSpan={colSpan} />
            </tr>
          )}
        </tbody>
        {totalPages >= 2 && (
          <tfoot>
            <tr>
              <CustomTablePagination
                rowsPerPageOptions={[]}
                colSpan={colSpan}
                count={rows.length}
                rowsPerPage={perPage}
                page={page}
                slotProps={{
                  select: {
                    "aria-label": "rows per page",
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </tr>
          </tfoot>
        )}
      </table>
    </Root>
  );
};

TableWithPagination.propTypes = {
  header: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.node.isRequired,
};

export default TableWithPagination;
