/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useMemo } from "react";

import { JOB_APPLICATION_MATCH_STATUS } from "lookup";
import { SearchContext } from "context/providers";

import UserCardList from "components/UserCardList";
import Tabs from "components/base/Tabs";

const ApplicationsTabs = () => {
  const { clearSearchState } = useContext(SearchContext);

  const renderCustomNoResults = (collectionKey) => () => {
    switch (collectionKey) {
      case "hits": {
        return (
          <p>
            There are no applicants to display. Please{" "}
            <a
              className="text-blue-500 cursor-pointer opacity-70 transition-all select-none hover:opacity-100 underline hover:no-underline"
              onClick={clearSearchState}
            >
              Clear Filters
            </a>{" "}
            or change filter criteria
          </p>
        );
      }

      default: {
        return <></>;
      }
    }
  };

  const tabsContent = useMemo(() => {
    const tabs = [
      {
        title: "All Users",
        icon: "users",
        type: "custom",
        renderCustomContent: () => (
          <UserCardList
            collectionKey="hits"
            renderCustomNoResults={renderCustomNoResults("hits")}
            customFilter="hideSkipped"
            showStatusColor
            showActions
            pagination
            stats
          />
        ),
      },
      {
        title: "Matched",
        icon: "heart",
        type: "custom",
        renderCustomContent: () => (
          <UserCardList
            collectionKey="match"
            noResultsMessage="There are no Matched applicants to display"
            filter={["status", "subStatus"]}
            excludedMatchStatuses={[
              JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
              JOB_APPLICATION_MATCH_STATUS.SKIPPED,
            ]}
          />
        ),
      },
      {
        title: "Shortlisted",
        icon: "focus",
        type: "custom",
        renderCustomContent: () => (
          <UserCardList
            collectionKey="match"
            noResultsMessage="There are no Shortlisted applicants to display"
            matchStatus={JOB_APPLICATION_MATCH_STATUS.SHORTLISTED}
          />
        ),
      },
      {
        title: "Skipped",
        icon: "skip",
        type: "custom",
        renderCustomContent: () => (
          <UserCardList
            collectionKey="match"
            noResultsMessage="There are no Skipped applicants to display"
            matchStatus={JOB_APPLICATION_MATCH_STATUS.SKIPPED}
          />
        ),
      },
      {
        title: "Best Fit",
        icon: "calculator",
        type: "custom",
        renderCustomContent: () => (
          <UserCardList
            collectionKey="bestFit"
            noResultsMessage="The system is unable to find a best fit candidate for this job"
            showStatusColor
            showActions
          />
        ),
      },
    ];

    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Tabs items={tabsContent} />;
};

export default ApplicationsTabs;
