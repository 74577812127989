import React from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";

import HelpText from "components/HelpText";

import "./index.css";

const CustomToggle = ({
  checked,
  valueKey,
  leftLabel,
  rightLabel,
  helpText,
  onChange,
}) => {
  const handleChange = ({ target: { checked } }) => {
    onChange(checked, valueKey);
  };

  return (
    <div className="flex">
      {leftLabel && <p className="mr-2">{leftLabel}</p>}

      <Toggle
        checked={checked}
        onChange={handleChange}
        icons={false}
        className="customToggle"
      />

      {rightLabel && <p className="ml-2">{rightLabel}</p>}

      {helpText && <HelpText text={helpText} className="ml-2" />}
    </div>
  );
};

CustomToggle.propTypes = {
  checked: PropTypes.bool,
  valueKey: PropTypes.string,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  helpText: PropTypes.string,
  onChange: PropTypes.func,
};

CustomToggle.defaultProps = {
  checked: true,
  valueKey: "",
  leftLabel: "",
  rightLabel: "",
  helpText: "",
  onChange: () => {},
};

export default CustomToggle;
