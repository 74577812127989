import { cloneDeep, sortBy } from "lodash";

import { JOB_OPPORTUNITY_STATUSES } from "lookup";
import api from "apiSingleton";

const ITEM_LIMIT = 500;

function sortByStatus(jobs) {
  const jobStatusesOrder = [
    JOB_OPPORTUNITY_STATUSES.ACTIVE,
    JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL,
    JOB_OPPORTUNITY_STATUSES.FULFILLED,
    JOB_OPPORTUNITY_STATUSES.CANCELLED,
    JOB_OPPORTUNITY_STATUSES.DRAFT,
  ];

  return sortBy(jobs, [
    function (job) {
      return jobStatusesOrder.indexOf(job.status);
    },
    "organization",
    "title",
  ]);
}

const mapMatches = (matches) => {
  if (matches?.items) {
    return matches.items.map((el) => ({
      ...el,
      isMatching: false,
      isShortlisting: false,
      isDeleting: false,
    }));
  }

  return [];
};

export async function initializeJobs() {
  const jobs = await api.jobs.list({
    filter: { status: { in: ["ACTIVE", "PENDINGAPPROVAL"] } },
    limit: ITEM_LIMIT,
  });

  const jobsByStatus = sortByStatus(jobs.data.listJobOpportunitys.items);

  const companyNames = {};
  const jobTypes = {};

  const jobOpps = [
    ...jobsByStatus?.map((el) => ({
      ...el,
      matches: { items: mapMatches(el.matches) },
    })),
  ];

  for (let job of jobOpps) {
    const organizationName = job?.organization;
    const customerName =
      job?.customer?.companyDetails?.name || job?.customer?.company;
    const jobType = job?.jobType?.title;

    if (organizationName && !companyNames[organizationName]) {
      companyNames[organizationName] = organizationName;
    } else if (customerName && !companyNames[customerName]) {
      companyNames[customerName] = customerName;
    }

    if (jobType && !jobTypes[jobType]) {
      jobTypes[jobType] = true;
    }
  }

  return { jobOpps, companyNames, jobTypes };
}

export async function initializeJob(id) {
  const job = await api.jobs.get({ id });

  return job.data.getJobOpportunity;
}

export function updateJobLocally(job, value, valueKey) {
  const clonedJob = cloneDeep(job);
  const updatedJob = { ...clonedJob, [valueKey]: value };

  return updatedJob;
}

export async function updateJob(id, payload) {
  const job = await api.jobs.update({
    input: {
      id,
      ...payload,
    },
  });

  return job;
}
