export const listJobOpportunitys = /* GraphQL */ `
  query ListJobOpportunitys(
    $filter: ModelJobOpportunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobOpportunitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        jobLength
        jobLengthInWeeks
        organization
        jobType {
          id
          title
        }
        maxRate {
          value
        }
        minRate {
          value
        }
        status
        title
        customer {
          id
          family_name
          given_name
          username
          companyDetails {
            name
          }
        }
        matches(limit: 1000) {
          items {
            status
          }
        }
      }
    }
  }
`;
export const getJobOpportunity = /* GraphQL */ `
  query GetJobOpportunity($id: ID!) {
    getJobOpportunity(id: $id) {
      organization
      customer {
        company
        companyDetails {
          name
        }
        notes {
          items {
            id
          }
        }
      }
      torcOwner {
        username
      }
      id
      notesCount
      jobLength
      jobLengthInWeeks
      jobType {
        id
        title
      }
      maxRate {
        value
      }
      minRate {
        value
      }
      overview
      requirements
      responsibilities
      skills {
        id
        name
      }
      startDate
      status
      timeOverlap
      timeCommitment
      timezone {
        label
        value
      }
      title
      matches(limit: 1000) {
        items {
          metadata
          notesCount
          application {
            creator
            user {
              createdAt
              email
              headshotKey
              username
              id
              talentSource
              additionalMarkup
              availability
              family_name
              given_name
              profileCompletion
              metadata
              ratePerHour {
                currency
                value
              }
              phone {
                number
                whatsAppAllowed
              }
              skills {
                name
                id
              }
              knownLanguages {
                level
                language
              }
              socialLinks {
                type
                value
              }
              status
              otherLinks {
                description
                name
                type
                value
                visibility
              }
              resumeLocation
            }
            preferredRate {
              # Preferred Rate on application should not be used
              value
            }
          }
          freelancerPitch
          customerRate {
            value
          }
          applicationId
          creator
          jobOpportunityId
          isAccepted
          status
          subStatus
          availableStartDate
          rate {
            value
          }
          updater
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getMatch = /* GraphQL */ `
  query GetMatch($applicationId: ID!, $jobOpportunityId: ID!) {
    getMatch(
      applicationId: $applicationId
      jobOpportunityId: $jobOpportunityId
    ) {
      applicationId
      jobOpportunityId
      isAccepted
      status
      subStatus
      rate {
        value
      }
    }
  }
`;
export const listJobOpportunityMatches = /* GraphQL */ `
  query ListJobOpportunityMatches($jobOpportunityId: ID!) {
    statuses: getJobOpportunity(id: $jobOpportunityId) {
      matches {
        items {
          status
          application {
            userId
          }
        }
      }
    }
  }
`;
export const listJobOpportunityNotes = /* GraphQL */ `
  query ListJobOpportunityNotes(
    $jobOpportunityId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobOpportunityNotes(
      jobOpportunityId: $jobOpportunityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        creator
        isPublic
        createdAt
        applicationId
        userId
      }
      nextToken
    }
  }
`;
export const listUserNotes = /* GraphQL */ `
  query ListUserNotes(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotes(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        creator
        isPublic
        createdAt
        applicationId
        userId
      }
      nextToken
    }
  }
`;
export const listMatchNotes = /* GraphQL */ `
  query ListMatchNotes(
    $applicationId: ID
    $jobOpportunityId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMatchNotes(
      applicationId: $applicationId
      jobOpportunityId: $jobOpportunityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        creator
        isPublic
        createdAt
        applicationId
        userId
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      notes {
        items {
          content
          creator
          isPublic
          createdAt
        }
      }
    }
  }
`;
export const getFieldHistory = /* GraphQL */ `
  query GetFieldHistory(
    $entity: String
    $key: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFieldHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFieldHistory(
      entity: $entity
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        oldValue
        newValue
        creator
        createdAt
      }
    }
  }
`;
export const getUserActivityHistory = /* GraphQL */ `
  query GetUserActivityHistory(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserActivityHistory(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        eventName
        message
        createdAt
      }
      nextToken
    }
  }
`;
export const getUserByUsername = /* GraphQL */ `
  query GetUserByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
      }
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
      }
    }
  }
`;
