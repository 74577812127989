import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  getButtonClassName,
  getButtonText,
} from "components/UserCardList/helpers/userCard";

import {
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_SUB_STATUS,
} from "lookup";
import { ModalContext } from "context/providers";

import CustomerRateButton from "./CustomerRateButton";
import SvgIcon from "components/base/SvgIcon";

const DEFAULT_BUTTON_CLASS_NAME =
  "transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex items-center";

const MatchActionButtons = ({ match, isLoading, updateMatch, removeMatch }) => {
  const { showModal } = useContext(ModalContext);

  const canBeFinalist =
    match.status === JOB_APPLICATION_MATCH_STATUS.APPLIED ||
    match.status === JOB_APPLICATION_MATCH_STATUS.MATCHED;

  const isNotFinalist =
    match?.subStatus !== JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST;

  const { oneOfButtonsLoading, notesCount, canBeMatched } = useMemo(() => {
    const oneOfButtonsLoading = !!Object.values(isLoading).filter((el) => !!el)
      .length;

    const { status, notesCount } = match;
    const canBeMatched = status === JOB_APPLICATION_MATCH_STATUS.SHORTLISTED;

    return { oneOfButtonsLoading, notesCount, canBeMatched };
  }, [isLoading, match]);

  return (
    <div className="flex items-center gap-4">
      {canBeFinalist && (
        <button
          className={classNames(
            DEFAULT_BUTTON_CLASS_NAME,
            isNotFinalist ? "border-2 border-green-400" : "bg-emerald-300",
            "text-emerald-800",
            getButtonClassName(
              "finalist",
              isLoading[JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST]
            )
          )}
          disabled={oneOfButtonsLoading}
          onClick={() =>
            updateMatch({
              subStatus: isNotFinalist
                ? JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST
                : JOB_APPLICATION_MATCH_SUB_STATUS.NULL,
            })
          }
        >
          <SvgIcon type={"checkBadge"} className="h-5 w-5" />

          <span className="ml-2">
            {getButtonText(
              isNotFinalist ? "finalist" : "removeFinalist",
              isLoading[
                isNotFinalist
                  ? JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST
                  : JOB_APPLICATION_MATCH_SUB_STATUS.NULL
              ]
            )}
          </span>
        </button>
      )}

      {canBeMatched && (
        <button
          className={classNames(
            DEFAULT_BUTTON_CLASS_NAME,
            "bg-green-700 text-green-800",
            getButtonClassName(
              "match",
              isLoading[JOB_APPLICATION_MATCH_STATUS.MATCHED]
            )
          )}
          disabled={oneOfButtonsLoading}
          onClick={() =>
            updateMatch({ status: JOB_APPLICATION_MATCH_STATUS.MATCHED })
          }
        >
          <SvgIcon type="heart" fill="#166534" className="w-[20px] h-[20px]" />

          <span className="ml-2">
            {getButtonText(
              "match",
              isLoading[JOB_APPLICATION_MATCH_STATUS.MATCHED]
            )}
          </span>
        </button>
      )}

      {!canBeMatched && (
        <CustomerRateButton
          match={match}
          disabled={oneOfButtonsLoading}
          desiredRate={match?.rate?.value}
        />
      )}

      <button
        className={classNames(
          DEFAULT_BUTTON_CLASS_NAME,
          "bg-red-300 hover:bg-red-400 text-red-800",
          getButtonClassName(
            "delete",
            isLoading[JOB_APPLICATION_MATCH_STATUS.DELETED]
          )
        )}
        disabled={oneOfButtonsLoading}
        onClick={removeMatch}
      >
        <SvgIcon type="delete" fill="red" className="w-[15px] h-[15px]" />

        <span className="ml-2">
          {getButtonText(
            "delete",
            isLoading[JOB_APPLICATION_MATCH_STATUS.DELETED]
          )}
        </span>
      </button>

      <button
        className={classNames(
          DEFAULT_BUTTON_CLASS_NAME,
          "bg-yellow-200 text-yellow-600"
        )}
        onClick={() =>
          showModal({
            type: "note",
            title: `Match Notes: ${match.application.user.username}`,
            applicationId: match.applicationId,
            isAllowedPublicNote: true,
          })
        }
      >
        <SvgIcon type="note" className="w-[15px] h-[15px]" fill="#857807" />

        <span className="ml-2">{`Notes${
          !!notesCount ? ` (${notesCount})` : ""
        }`}</span>
      </button>
    </div>
  );
};

MatchActionButtons.propTypes = {
  match: PropTypes.object,
  isLoading: PropTypes.object,
  callMatchAction: PropTypes.func,
};

MatchActionButtons.defaultProps = {
  match: {},
  isLoading: {},
  callMatchAction: () => {},
};

export default MatchActionButtons;
