import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { round } from "lodash";

import api from "apiSingleton";
import { JobsContext } from "context/providers";
import { JOB_APPLICATION_MATCH_STATUS } from "lookup";

import Modal from "components/Modals";
import SvgIcon from "components/base/SvgIcon";

const EXCLUDED_RATE_BUTTON_STATUSES = [
  JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER,
  JOB_APPLICATION_MATCH_STATUS.SKIPPED,
];

const DEFAULT_BUTTON_CLASS_NAME =
  "transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex items-center";

const getStandardMarkup = (desiredRate) => round(desiredRate * 1.5, 0);

// Display our Standard Markup
const StandardMarkup = ({ desiredRate }) => {
  if (!desiredRate) {
    return null;
  }

  return (
    <span className="font-bold">
      {"$" + getStandardMarkup(desiredRate)}{" "}
      <span
        className="font-light"
        title="1.5 times the desired rate of freelancer"
      >
        ( 1.5x )
      </span>
    </span>
  );
};

//Compute Calculated Markup based on current inputRate
const CalculatedMarkup = ({ desiredRate, currentRate }) => {
  if (!desiredRate) {
    return null;
  }

  const markup = ((currentRate - desiredRate) / currentRate) * 100;
  let markupPercent = round(markup, 2);

  if (!isFinite(markupPercent)) {
    markupPercent = 0;
  }

  return (
    <span className="font-bold">
      <span className={markupPercent > 0 ? "text-green-500" : "text-red-500"}>
        {markupPercent.toFixed(2)}
        {"%"}
      </span>
    </span>
  );
};

const CustomerRateButton = ({ match, disabled, desiredRate }) => {
  const { initJob } = useContext(JobsContext);

  const params = useParams();

  const [settingRate, setSettingRate] = useState(false);
  const [inputNum, setInputNum] = useState(
    getStandardMarkup(desiredRate ?? match?.customerRate?.value ?? 0)
  );
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const setRate = async (addRate) => {
    try {
      setLoading(true);

      const rate = addRate ? inputNum : 0;
      const customerRate = { value: rate, currency: "USD" };

      await api.match.update({
        input: {
          applicationId: match?.applicationId,
          jobOpportunityId: match?.jobOpportunityId,
          customerRate,
          status: match?.status,
        },
      });

      await initJob(params.id);

      setSettingRate(false);
      setLoading(false);
    } catch (e) {
      console.error("setRate error: ", e);

      setLoading(false);
      setErr(true);
    }
  };

  return (
    <>
      <Modal
        isVisible={settingRate}
        title={
          match?.customerRate?.value ? "Edit Customer Rate" : "Customer Rate"
        }
        className="!w-auto !overflow-y-hidden"
        onClose={() => {
          setSettingRate(false);
          setErr(false);
        }}
      >
        <div className="flex flex-col h-full justify-between">
          <div className="flex flex-col">
            <input
              id="rate"
              type="number"
              className="border border-grey rounded-md w-32 px-2 max-w-full"
              value={inputNum}
              min="0"
              onChange={(e) => setInputNum(e.target.value)}
            />
            <p className="mt-5">
              <span>Desired Rate: </span>
              <span className="font-bold">${desiredRate}</span>
            </p>
            <p>
              <span>Standard Rate w/ Markup : </span>
              <StandardMarkup desiredRate={desiredRate} />
            </p>
            <p>
              <span>Calculated Markup : </span>
              <CalculatedMarkup
                desiredRate={desiredRate}
                currentRate={inputNum}
              />
            </p>
          </div>
          <div className="w-full flex justify-between mt-4">
            <button
              className="mr-3 bg-blue rounded-md text-blue-700 font-bold w-32 p-1 whitespace-nowrap"
              onClick={() => setRate(true)}
            >
              {loading ? "setting rate..." : "Set Rate"}
            </button>{" "}
            <button
              className="ml-3 bg-red-300 rounded-md text-red-500 font-bold w-32 p-1 whitespace-nowrap"
              onClick={() => setRate(false)}
            >
              Void Rate
            </button>
          </div>
          {err && (
            <div className="text-red-400">
              Something went wrong, please try again
            </div>
          )}
        </div>
      </Modal>

      {!EXCLUDED_RATE_BUTTON_STATUSES.includes(match?.status) && (
        <button
          className={classNames(
            DEFAULT_BUTTON_CLASS_NAME,
            "bg-sky-300 hover:bg-sky-400 text-sky-800"
          )}
          onClick={() => setSettingRate(true)}
          disabled={disabled}
        >
          <SvgIcon type="focus" fill="#075985" className="w-[20px] h-[20px]" />

          <span className="ml-2">Customer rate</span>
        </button>
      )}
    </>
  );
};

export default CustomerRateButton;
