import React from "react";
import classNames from "classnames";

import Input from "components/base/Input";
import Select from "components/base/Select";

const FORM_ELEMENTS_BY_TYPE = {
  input: Input,
  select: Select,
};

const FormModal = ({
  successMessage,
  errorMessage,
  isSubmitting,
  inputs,
  renderPreview,
  onInputChange,
  onSubmit,
  onCancel,
  ref,
}) => {
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await onSubmit();
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = ({ value, valueKey }) => {
    onInputChange({ value, valueKey });
  };

  const renderInputs = () => {
    return Object.keys(inputs).map((inputKey) => {
      const { type, label } = inputs[inputKey];
      const Component = FORM_ELEMENTS_BY_TYPE[type];

      if (Component) {
        return (
          <Component
            key={label}
            onChange={handleInputChange}
            valueKey={inputKey}
            {...inputs[inputKey]}
          />
        );
      }

      return null;
    });
  };

  return (
    <div className="bg-white z-40 rounded-3xl flex flex-col justify-between items-center max-w-xl">
      <form ref={ref} className="w-full mt-6" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-6 w-full">{renderInputs()}</div>

        {!!renderPreview && renderPreview()}

        <button type="submit" hidden />
      </form>

      <div className="flex justify-between mt-6 w-full">
        <button
          onClick={onCancel}
          disabled={isSubmitting}
          className={classNames(
            "bg-red-400 hover:bg-red-600 p-2 font-bold rounded-lg",
            {
              "cursor-not-allowed animate-pulse": isSubmitting,
            }
          )}
        >
          Cancel
        </button>

        {errorMessage && (
          <div className="text-red-500 self-center font-nexa font-bold sm:w-1/2 w-1/2 flex justify-center text-center">
            {errorMessage}
          </div>
        )}

        {successMessage && (
          <div className="text-indigo-500 self-center font-nexa font-bold sm:w-1/2 w-1/2 flex justify-center text-center">
            <p className="text-indigo-500">{successMessage}</p>
          </div>
        )}

        <button
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={classNames(
            "bg-green-400 hover:bg-green-600 p-2 font-bold rounded-lg",
            {
              "cursor-not-allowed animate-pulse": isSubmitting,
            }
          )}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default FormModal;
