import React, { useContext, useMemo } from "react";
import algoliasearch from "algoliasearch/lite";
import { Configure, InstantSearch } from "react-instantsearch-dom";

import { JOB_APPLICATION_MATCH_STATUS, USER_STATUS } from "lookup";
import { MatchContext, SearchContext } from "context/providers";

import ResultsContainer from "./Results";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const ALGOLIA_FILTERS = `status:${USER_STATUS.ACTIVE}`;

const SearchContainer = ({ children }) => {
  const { searchState, setSearchState, hideSkipped } =
    useContext(SearchContext);
  const { matches } = useContext(MatchContext);

  const skippedObjectIDs = useMemo(() => {
    const skippedMatchesUserIDs = matches
      .filter((match) => match.status === JOB_APPLICATION_MATCH_STATUS.SKIPPED)
      .map((match) => match.application.user.id);

    if (!hideSkipped) {
      return [];
    }

    return skippedMatchesUserIDs;
  }, [matches, hideSkipped]);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={process.env.REACT_APP_INDEX_NAME}
      onSearchStateChange={setSearchState}
      searchState={searchState}
    >
      <ResultsContainer>{children}</ResultsContainer>

      <Configure
        filters={ALGOLIA_FILTERS}
        facets={["objectID"]}
        facetsExcludes={{
          objectID: skippedObjectIDs,
        }}
      />
    </InstantSearch>
  );
};

export default SearchContainer;
