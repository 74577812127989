import React from "react";
import PropTypes from "prop-types";
import { Panel } from "react-instantsearch-dom";

import CustomToggle from "components/base/Toggle";

const Header = ({
  title,
  toggle,
  checked,
  valueKey,
  leftToggleLabel,
  rightToggleLabel,
  helpText,
  onChange,
}) => {
  if (toggle) {
    return (
      <div className="flex justify-between relative z-30">
        <p>{title}</p>

        <CustomToggle
          checked={checked}
          valueKey={valueKey}
          leftLabel={leftToggleLabel}
          rightLabel={rightToggleLabel}
          helpText={helpText}
          onChange={onChange}
        />
      </div>
    );
  }

  return <p>{title}</p>;
};

const CustomPanel = ({
  title,
  toggle,
  checked,
  valueKey,
  leftToggleLabel,
  rightToggleLabel,
  helpText,
  onChange,
  children,
}) => {
  const handleChange = (value, valueKey) => {
    onChange(value, valueKey);
  };

  return (
    <Panel
      header={
        <Header
          title={title}
          toggle={toggle}
          checked={checked}
          valueKey={valueKey}
          leftToggleLabel={leftToggleLabel}
          rightToggleLabel={rightToggleLabel}
          helpText={helpText}
          onChange={handleChange}
        />
      }
    >
      {children}
    </Panel>
  );
};

CustomPanel.propTypes = {
  title: PropTypes.string,
  toggle: PropTypes.bool,
  checked: PropTypes.bool,
  valueKey: PropTypes.string,
  helpText: PropTypes.string,
  onChange: PropTypes.func,
};

CustomPanel.defaultProps = {
  title: "",
  toggle: false,
  checked: true,
  valueKey: "",
  helpText: "",
  onChange: () => {},
};

export default CustomPanel;
