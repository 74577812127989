import React from "react";
import PropTypes from "prop-types";

import Checkbox from "components/base/Checkbox";

const Filters = ({ filters, onChange }) => {
  const handleFilterChange = (filtersKey, filterKey, value) => {
    const output = {
      ...filters,
      [filtersKey]: {
        ...filters[filtersKey],
        keys: {
          ...filters[filtersKey]["keys"],
          [filterKey]: { ...filters[filtersKey]["keys"][filterKey], value },
        },
      },
    };
    const boolArray = [];
    for (const ele in output.status.keys) {
      boolArray.push(output.status.keys[ele].value);
    }
    const statusFiltersNotActive = boolArray.every((e) => e === false);

    if (statusFiltersNotActive) {
      output.subStatus.filterCondition = false;
    } else {
      output.subStatus.filterCondition = true;
    }

    onChange(output);
  };

  return (
    <div className="flex flex-col">
      {Object.keys(filters).map((filtersKey) => {
        const filtersByKey = filters[filtersKey].keys || {};

        return (
          <div key={filtersKey} className="flex">
            {Object.keys(filtersByKey).map((filterKey) => {
              const filterByKey = filtersByKey[filterKey];

              return (
                <div key={filterKey} className="flex">
                  <Checkbox
                    label={filterByKey.label}
                    checked={filterByKey.value}
                    className="mr-6 whitespace-nowrap"
                    onChange={(value) =>
                      handleFilterChange(filtersKey, filterKey, value)
                    }
                  />
                </div>
              );
            })}
            {filtersKey === "subStatus" &&
              !!Object.keys(filtersByKey).length && (
                <div className="mt-2 flex items-center"></div>
              )}
          </div>
        );
      })}
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func,
};

Filters.defaultProps = {
  filters: {},
  onChange: () => {},
};

export default Filters;
