import produce from "immer";

import { contextConstants } from "context/constants";

export const initialState = {
  matches: [],
  bestFit: [],
};

export const reducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case contextConstants.match.MATCHES_LOADED: {
      draft.matches = payload.matches;
      break;
    }

    case contextConstants.match.BEST_FIT_LOADED: {
      draft.bestFit = payload.bestFit;
      break;
    }

    default: {
      break;
    }
  }
});
